import React, { useEffect, useRef, useState } from "react";
import "./EventCalendar.scss";
import PropTypes from "prop-types";
import { Card, Col, Row } from "react-bootstrap";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { apiCall } from "helpers/apiCalls";
import ListLoading from "module/common/ListLoading";
import {
  crmDashboardBootstrapColors,
  crmDashboardColors,
} from "../CrmStats/CrmStatsData";
import ReminderForm from "module/common/Reminder/ReminderForm";
import NothingToShow from "../NothingToShow";
import useAxisproTranslate from "hooks/useAxisproTranslate";
import {
  getFormattedCurrentDateTime,
  getFormattedEndDateTime,
} from "helpers/utils";

const EventCalendar = ({ setRefreshTodo }) => {
  const calendarRef = useRef();
  const Translate = useAxisproTranslate();
  const [calendarApi, setCalendarApi] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([{}]);
  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await apiCall({
        url: "crm/reminders",
        params: {
          from_date: getFormattedCurrentDateTime("YYYY-MM-DD"),
          to_date: getFormattedEndDateTime(null, "YYYY-MM-DD", "days", 6),
        },
      });
      setData({
        ...response,
        data: response?.data?.map((item, index) => ({
          ...item,
          color: crmDashboardColors[index % crmDashboardColors.length],
          title: item?.remarks,
          start: item?.next_reminder_date_time.split(" ")?.[0],
          allDay: true,
          backgroundColor:
            crmDashboardBootstrapColors[
              index % crmDashboardBootstrapColors.length
            ],
          borderColor:
            crmDashboardBootstrapColors[
              index % crmDashboardBootstrapColors.length
            ],
        })),
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleEventClick = (eventsInfo) => {
    setReminderDetails({
      show: true,
      reminderId: eventsInfo.id,
    });
  };

  function formatDate(dateString) {
    const dateObj = new Date(dateString);
    const day = dateObj.getDate().toString().padStart(2, "0");
    const month = dateObj.toLocaleString("en-US", { month: "short" });
    const year = dateObj.getFullYear();
    return `${day} ${month}, ${year}`;
  }

  const [reminderDetails, setReminderDetails] = useState({
    show: false,
    details: {
      is_dashboard_reminder: true,
    },
    data: {
      is_dashboard_reminder: true,
    },
    reminderId: "",
  });

  const customButtons = {
    myCustomButton: {
      text: Translate("New Schedule"),
      click: () => {
        setReminderDetails({
          show: true,
          details: {
            is_dashboard_reminder: true,
          },
        });
      },
    },
  };

  useEffect(() => {
    setCalendarApi(calendarRef?.current?.getApi());
  }, []);

  const convertDateFormat = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const handleRefresh = () => {
    fetchData();
    setRefreshTodo((prev) => !prev);
  };

  return (
    <>
      <Card className="overflow-hidden h-100 card-main">
        {isLoading ? (
          <div className="p-3">
            <ListLoading style={{ maxHeight: "45vh", overflow: "hidden" }} />
          </div>
        ) : (
          <Card.Body className="p-0 management-calendar">
            <Row className="g-3">
              <Col md={8}>
                <div className="calendar-outline px-3 mt-3">
                  <FullCalendar
                    ref={calendarRef}
                    customButtons={customButtons}
                    headerToolbar={{
                      left: "myCustomButton",
                      center: "",
                      right: "prev today next",
                    }}
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    themeSystem="bootstrap"
                    initialView="dayGridMonth"
                    height={400}
                    events={data?.data}
                    dateClick={(info) => {
                      // setIsOpenScheduleModal(true);
                      // setScheduleStartDate(info.date);
                      setReminderDetails((prev) => ({
                        ...prev,
                        show: true,
                        details: {
                          is_dashboard_reminder: true,
                        },
                        data: {
                          reminder_date_time: convertDateFormat(info.date),
                          is_dashboard_reminder: true,
                          reminder_date: info.dateStr,
                        },
                      }));
                    }}
                  />
                </div>
              </Col>
              <Col md={4} className="bg-body-tertiary pt-3">
                <div className="px-3">
                  <h4 className="mb-0 fs-1 fw-bold">
                    {calendarApi?.currentDataManager?.data?.viewTitle}
                  </h4>
                  <p className="text-500 mb-0">
                    {new Date().toLocaleString("en-us", {
                      weekday: "long",
                    })}
                  </p>
                  {data?.data?.length > 0 ? (
                    <ul
                      className="list-unstyled mt-3 scrollbar management-calendar-events"
                      id="management-calendar-events"
                    >
                      {data?.data?.map((events) => (
                        <li
                          className="border-top pt-3 mb-3 pb-1 cursor-pointer"
                          onClick={() => handleEventClick(events)}
                          key={events.id}
                        >
                          <div
                            className={`border-start border-3 ps-3 mt-1 border-${events.color}`}
                          >
                            <h6 className="mb-1 fw-semibold text-700">
                              {events.remarks}
                            </h6>
                            <p className="fs-11 text-600 mb-0">
                              {formatDate(events.next_reminder_date_time) || ""}{" "}
                            </p>
                          </div>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <div style={{ height: "44vh" }}>
                      <NothingToShow />
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </Card.Body>
        )}
      </Card>
      <ReminderForm
        show={reminderDetails?.show}
        details={reminderDetails?.details}
        onHide={() => setReminderDetails({ show: false, details: {} })}
        data={reminderDetails?.data}
        refreshData={handleRefresh}
        reminderId={reminderDetails?.reminderId}
      />
    </>
  );
};

EventCalendar.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string,
      startTime: PropTypes.string,
      endTime: PropTypes.string,
      color: PropTypes.string,
      classNames: PropTypes.string,
    })
  ),
};

export default EventCalendar;
