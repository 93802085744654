import { getFormattedCurrentDateTime, getFormattedEndDateTime } from "./utils";

export const installationFormKeys = {
  company_name: "",
  business_type: "",
  base_currency: null,
  base_currency_ref: null,
  no_of_employees: "",
  company_email: "",
  company_phone: "",
  place: "",
  address: "",
  first_name: "",
  last_name: "",
  user_email: "",
  user_phone: "",
  username: "",
  password: "",
  c_password: "",
  trn_no: "",
  tax_group: null,
  tax_group_ref: null,
  company_logo: null,
};

export const registerFormKeys = {
  first_name: "",
  last_name: "",
  email: "",
  username: "",
  password: "",
  c_password: "",
  branch_id: "",
  branch_id_ref: { label: "", value: "" },
};

export const loginFormKeys = {
  username: "",
  password: "",
  app_name: "CRM",
};

export const accountRecoveryFormKeys = {
  username: "",
};

export const resetPasswordFormKeys = {
  password: "",
  c_password: "",
};

export const employeeFormKeys = {
  first_name: "",
  middle_name: "",
  last_name: "",
  dob: "",
  gender: "",
  gender_ref: { label: "", value: "" },
  username: "",
  password: "",
  c_password: "",
  user_group_id: "",
  designation_name: "",
  designation_id: "",
  designation_id_ref: {},
  department_name: "",
  department_id: "",
  department_id_ref: {},
  phone: "",
  office_number: "",
  company_name: "",
  company_id: "",
  company_id_ref: {},
  fax: "",
  email: "",
  address: "",
  working_hrs: "",
  role_id: "",
  role_id_ref: {},
  time_zone: "",
  time_zone_ref: {},
  status: "Active",
  hourly_rate: "",
};

export const leadStatusFormKeys = {
  status: "",
};
export const teamFormKeys = {
  name: "",
  description: "",
  team_lead_id: "",
  team_lead_id_ref: { label: "", value: "" },
  team_members: [],
  team_members_ref: [],
};

export const departmentFormKeys = {
  name: "",
  department_ref: {},
};
export const teamMemberFormKeys = {
  team_id: "",
  team_id_ref: {},
  team_member_id: "",
  team_member_id_ref: {},
};
export const campaignFormKeys = {
  name: "",
  start_date: "",
  end_date: "",
  sponsor: "",
  type: "",
  type_ref: { label: "", value: "" },
  budget: 0,
  revenue: 0,
  status: "active",
  status_ref: { label: "active", value: "active" },
  product_id: "",
  product_id_ref: { label: "", value: "" },
  target_audience_count: 0,
  actual_audience_count: 0,
  target_audience_type: "",
  assigned_to: "",
  assigned_to_ref: { label: "", value: "" },
  description: "",
  actual_cost: 0,
  expected_revenue: 0,
  expected_response_count: 0,
  actual_response_count: 0,
  expected_sales_count: 0,
  actual_sales_count: 0,
  expected_roi: 0,
  actual_roi: 0,
  parent_id: "",
  num_sent: 0,
  location: "",
};
export const todoFormKeys = {
  title: "",
  description: "",
  label: "",
  assigned_to: "",
  assigned_to_ref: { label: "", value: "" },
  status: "",
  status_ref: {},
  team_id: "",
  team_id_ref: { label: "", value: "" },
  due_date: "",
};
export const estimationFormKeys = {
  date: "",
  valid_until: "",
  notes: "",
  status: "",
  customer_id: "",
  customer_id_ref: "",
  details: [],
};
export const leadSourceFormKeys = {
  source_name: "",
  description: "",
};
export const leadStageFormKeys = {
  name: "",
};
export const leadsFormKeys = {
  title: "",
  is_company: 1,
  name: "",
  phone_no: "",
  email: "",
  company_name: "",
  company_name_ref: { label: "", value: "" },
  lead_source: "",
  lead_source_ref: { label: "", value: "" },
  lead_priority: "",
  lead_priority_ref: {
    label: "",
    value: "",
  },
  industry: "",
  industry_ref: { label: "", value: "" },
  lead_owner: "",
  lead_owner_ref: "",
  annual_revenue: "",
  fax: "",
  no_of_employees: "",
  description: "",
  address: "",
  website: "",
  lead_status: "",
  product_id: [],
  product_id_ref: [],
  lead_status_ref: {
    label: "",
    value: "",
  },
  generated_on: "",
  lead_assignee: "",
  lead_assignee_ref: {
    label: "",
    value: "",
  },
  person_name: "",
  person_type: "",
  person_type_ref: {
    label: "",
    value: "",
  },
  social_media_links: [],
  campaign_id: "",
  campaign_id_ref: {
    label: "",
    value: "",
  },
  contacts: [
    {
      contact_first_name: "",
      contact_last_name: "",
      contact_email: "",
      contact_phone_no: "",
      contact_secondary_email: "",
      contact_address: "",
      contact_city: "",
      contact_state: "",
      contact_country: "",
      contact_zip_code: "",
      contact_website: "",
      is_primary_contact: 1,
    },
  ],
  business_current_status: "",
  business_current_status_ref: {
    label: "",
    value: "",
  },
  geo_tag_text: "",
};

export const designationFormKeys = {
  name: "",
};

export const projectFormKeys = {
  title: "",
  customer_id: "",
  customer_id_ref: "",
  start_date: "",
  end_date: "",
  price: "",
  status: "open",
  status_ref: { label: "Open", value: "open" },
  description: "",
  project_logo: "",
  coordinator: "",
  coordinator_ref: { label: "", value: "" },
  products: [],
  products_ref: [],
};

export const tasksFormKeys = {
  project_id: "",
  project_id_ref: { value: "", label: "" },
  title: "",
  description: "<p><br></p>",
  start_date: getFormattedCurrentDateTime("YYYY-MM-DD HH:mm:ss"),
  end_date: getFormattedEndDateTime(null, "YYYY-MM-DD HH:mm:ss", "minutes", 30),
  assigned_to: "",
  assigned_to_ref: "",
  coordinator: "",
  coordinator_ref: { value: "", label: "" },
  status: "to_do",
  status_ref: { value: "to_do", label: "To Do" },
  labels: {},
  collaborators: {},
  priority: "Medium",
  priority_ref: { value: "Medium", label: "Medium" },
  sprint_id: "",
  sprint_id_ref: { label: "", value: "" },
  product_id: "",
  product_id_ref: { label: "", value: "" },
};

export const commentFormKeys = {
  subject_id: "",
  subject_type: "task",
  comment: "",
  files: [],
};

export const ticketsFormKeys = {
  title: "",
  // customer_id: "",
  // customer_id_ref: { label: "", value: "" },
  project_id: "",
  project_id_ref: { label: "", value: "" },
  ticket_type_id: "",
  ticket_type_id_ref: { label: "", value: "" },
  priority: "Medium",
  priority_ref: { label: "Medium", value: "Medium" },
  ticket_origin: "web",
  ticket_origin_ref: { label: "Web", value: "web" },
  status: "new",
  status_ref: { label: "New", value: "new" },
  description: "",
  assigned_to: "",
  assigned_to_ref: { label: "", value: "" },
  files: [],
  ticket_date_time: "",
  end_date_time: "",
  product_id: "",
  product_id_ref: { label: "", value: "" },
};

export const ticketTypeFormKeys = {
  title: "",
  is_default: 0,
};

export const labelsFormKeys = {
  label: "",
  description: "",
  colour: "",
  list_colours: [],
  subject_type: "",
  subject_type_ref: { label: "", value: "" },
};

export const announcementFormKeys = {
  title: "",
  description: "",
  start_date: "",
  end_date: "",
  file: [],
  inactive: 0,
};
export const collaboratorsFormKeys = {
  task_id: "",
  collaborators: [],
};
export const labeslsFormKeys = {
  task_id: "",
  labels: [],
};

export const attachmentsFormKeys = {
  id: "",
  type: "",
  files: [],
};

export const clientFormKeys = {
  name: "",
  address: "",
  currency_code: "",
  currency_code_ref: { label: "", value: "" },
  sales_type_id: "",
  sales_type_id_ref: { label: "", value: "" },
  credit_status_id: "",
  credit_status_id_ref: { label: "", value: "" },
  email: "",
  phone: "",
  notes: "",
  contact_person: "",
  salesman_id: "",
  salesman_id_ref: { label: "", value: "" },
  payment_term_id: "",
  payment_term_id_ref: { label: "", value: "" },
  tax_id: "",
  tax_id_ref: { label: "", value: "" },
  trade_license_no: "",
  customer_type: "INDIVIDUAL",
  credit_limit: "",
  opening_balance: "",
  trn_no: "",
  created_from_crm: 1,
  username: "",
  password: "",
};

export const clientContactFormKeys = {
  contact_name: "",
  contact_email: "",
  contact_mobile: "",
  contact_address: "",
};

export const addProjectMembers = {
  members: [],
  members_ref: [],
};
export const AddAssigneeFormKeys = {
  assinee: "",
  assinee_ref: { label: "", value: "" },
};

export const opportunityFormKeys = {
  title: "",
  description: "",
  stage: "Qualification",
  stage_ref: { label: "Qualification", value: "Qualification" },
  campaign_id: "",
  campaign_id_ref: { label: "", value: "" },
  lead_id: "",
  lead_id_ref: { label: "", value: "" },
  customer_id: "",
  customer_id_ref: { label: "", value: "" },
  amount: "",
  type: "",
  type_ref: { label: "", value: "" },
  estimator_id: "",
  estimator_id_ref: { label: "", value: "" },
  time_frame: "",
  time_frame_ref: { label: "", value: "" },
  expected_amount: "",
  opportunity_created_at: "",
  expected_closing_date: getFormattedEndDateTime(
    null,
    "YYYY-MM-DD",
    "days",
    30
  ),
  opening_date: "",
  closing_date: "",
  invoicing_probability_date: "",
  proposal_created_date: "",
  proposal_last_revision_date: "",
  cost_amount: "",
};
export const LeasAddContactFormKeys = {
  subject_type: "",
  subject_id: "",
  first_name: "",
  last_name: "",
  email: "",
  secondary_email: "",
  address: "",
  country: "",
  state: "",
  city: "",
  zip_code: "",
  website: "",
  phone_no: "",
  isCompany: 0,
  is_primary: 0,
};

export const leadAddCallFormKeys = {
  related_to_type: "",
  related_to_id: "",
  type: "Outbound",
  type_ref: {
    label: "Outbound",
    value: "Outbound",
  },
  status: "SCHEDULED",
  status_ref: {
    label: "SCHEDULED",
    value: "SCHEDULED",
  },
  purpose: "",
  out_call_result: "",
  start_time: getFormattedCurrentDateTime("YYYY-MM-DD HH:mm:ss"),
  end_time: getFormattedEndDateTime(null, "YYYY-MM-DD HH:mm:ss", "minutes", 30),
  call_to: "",
  description: "",
};
export const leadAddMeetingFormKeys = {
  title: "",
  description: "",
  location: "",
  start_time: getFormattedCurrentDateTime("YYYY-MM-DD HH:mm:ss"),
  end_time: getFormattedEndDateTime(null, "YYYY-MM-DD HH:mm:ss", "minutes", 30),
  host_user_id: "",
  related_to_type: "",
  related_to_id: "",
  status: "SCHEDULED",
  status_ref: {
    label: "SCHEDULED",
    value: "SCHEDULED",
  },
  participants: [],
};
export const enquiryFormKeys = {
  email: "",
  message: "",
  name: "",
  phone: "",
  recaptcha: "",
};
export const leadConversionFormKeys = {
  is_existing_account: 0,
  account_name: "",
  currency_code: "",
  currency_code_ref: { label: "", value: "" },
  customer_id: "",
  customer_id_ref: { label: "", value: "" },
  is_existing_contact: 1,
  first_name: "",
  phone_no: "",
  contact_id: "",
  contact_id_ref: { label: "", value: "" },
  lead_id: "",
  stage: "",
  opportunity_type: "",
  opportunity_type_ref: { label: "", value: "" },
  opportunity_time_frame: "",
  opportunity_time_frame_ref: { label: "", value: "" },
  opportunity_created_date: "",
  expected_closing_date: "",
  expected_amount: "",
};

export const invitationFormKeys = {
  email: "",
  role_id: "",
  role_id_ref: "",
  expiring_time: "",
};

export const batchNumberFormKeys = {
  mfg_date: "",
  exp_date: "",
  description: "",
};

export const quotationFormKeys = {
  draft_id: "",
  trans_type: "SQ",
  customer_id: "",
  customer_id_ref: {
    label: "",
    value: "",
  },
  sales_type_id: "",
  sales_type_id_ref: "",
  trans_date: "",
  expected_delivery_date: "",
  cost_center_id: "",
  cost_center_id_ref: "",
  payment_term_id: "",
  payment_term_id_ref: "",
  memo: "",
  mobile: "",
  email: "",
  details: [],
  terms_and_conditions: "",
  customer_reference: "",
  salesman_id: "",
  salesman_id_ref: "",
  custom_fields: {},
  opportunity_id: "",
  opportunity_id_ref: {
    label: "",
    value: "",
  },
  is_from_crm: 1,
};

export const productsFormKeys = {
  name: "",
  description: "",
};

export const clientKYCFormKeys = {
  id: "",
  type: "",
  document_type: "",
  document_type_ref: { label: "", value: "" },
  expiry_date: "",
  files: [],
};

export const partnersFormKeys = {
  partners: [],
  opportunity_id: "",
};

export const partnersItemFormKeys = {
  name: "",
  role: "",
};

export const sprintFormKeys = {
  name: "",
  description: "",
  start_date_time: "",
  end_date_time: "",
  status: "",
  status_ref: { value: "", label: "" },
  project_id: "",
  project_id_ref: { value: "", label: "" },
  remark: "",
};

export const commonTaskFilterFormKeys = {
  date_range: "",
  date_range_for: "",
  date_range_for_ref: "",
  from_date: "",
  to_date: "",
  task_no: "",
  title: "",
  project_id: "",
  project_id_ref: "",
  coordinator: "",
  coordinator_ref: "",
  assignee: "",
  assignee_ref: "",
  priority: "",
  priority_ref: "",
  created_by: "",
  created_by_ref: "",
  status_filter: [],
  status_filter_ref: [],
  unassigned: "",
  sprint_id: "",
  sprint_id_ref: "",
  collaborator_id: "",
  collaborator_id_ref: "",
  product_id: [],
  product_id_ref: [],
  ticket_type_id: "",
  ticket_type_id_ref: "",
  ticket_no: "",
};

export const commonTicketFilterFormKeys = {
  date_range: "",
  date_range_for: "",
  date_range_for_ref: { value: "", label: "" },
  from_date: "",
  to_date: "",
  title: "",
  project_id: "",
  project_id_ref: { value: "", label: "" },
  type: "",
  type_ref: { value: "", label: "" },
  origin: "",
  origin_ref: { value: "", label: "" },
  assignee: "",
  assignee_ref: { value: "", label: "" },
  priority: "",
  priority_ref: { value: "", label: "" },
  account_id: "",
  account_id_ref: { value: "", label: "" },
  status: "",
  status_ref: { value: "", label: "" },
  order_by: "desc",
  order_by_ref: { value: "desc", label: "Descending" },
  billable: "",
  billable_ref: { value: "", label: "" },
  created_by: "",
  created_by_ref: { value: "", label: "" },
  product_id: [],
  product_id_ref: [],
};

export const commonEmployeeFilterFormKeys = {
  full_name: "",
  designation_id: "",
  designation_id_ref: "",
  department_id: "",
  department_id_ref: "",
  role_id: "",
  role_id_ref: "",
  status: "",
  status_ref: "",
};

export const commonLeadFilterFormKeys = {
  title: "",
  company_name: "",
  is_company: 0,
  is_individual: 0,
  lead_owner: "",
  from_date: "",
  to_date: "",
  date_range: "",
  date_range_for: "",
  lead_owner_ref: "",
  is_converted: "",
  is_converted_ref: "",
  lead_source: "",
  lead_source_ref: "",
  lead_priority: "",
  lead_priority_ref: "",
  lead_status: "",
  lead_status_ref: "",
  reference: "",
};

export const commonUsersFilterFormKeys = {
  search: "",
};

export const commonRolesFilterFormKeys = {
  name: "",
};

export const leadStatusEditFormKeys = {
  lead_id: "",
  description: "",
  status_id: "",
  status_id_ref: { value: "", label: "" },
};

export const opportunityStageChangeFormKeys = {
  opportunity_id: "",
  description: "",
  stage_to: "",
  stage_to_ref: { value: "", label: "" },
};

export const commonLeadStatusFilterFormKeys = {
  name: "",
};

export const commonLeadSourceFilterFormKeys = {
  name: "",
};

export const commonCampaignFilterFormKeys = {
  date_range: "",
  date_range_for: "",
  from_date: "",
  to_date: "",
  name: "",
  sponsor: "",
  type: "",
  type_ref: "",
  status: "",
  status_ref: "",
  product_id: "",
  product_id_ref: "",
  assigned_to: "",
  assigned_to_ref: "",
  location: "",
};

export const commonOpportunityFilterFormKeys = {
  date_range: "",
  date_range_for: "",
  date_range_for_ref: "",
  from_date: "",
  to_date: "",
  title: "",
  type: "",
  type_ref: "",
  customer_id: "",
  customer_id_ref: "",
  stage: "",
  stage_ref: "",
  lead_id: "",
  lead_id_ref: "",
  campaign_id: "",
  campaign_id_ref: "",
  reference: "",
  project_id: "",
  project_id_ref: "",
};

export const commonTeamsFilterFormKeys = {
  name: "",
  team_lead_id: "",
  team_lead_id_ref: { value: "", label: "" },
};

export const commonDepartmentsFilterFormKeys = {
  name: "",
};

export const commonDesignationFilterFormKeys = {
  name: "",
};

export const commonClientFilterFormKeys = {
  customer_id: "",
  customer_id_ref: "",
  customer_code: "",
  customer_type: "",
  customer_type_ref: "",
  currency_code: "",
  currency_code_ref: "",
  status: "",
  status_ref: "",
};

export const commonSprintFilterFormKeys = {
  date_range: "",
  date_range_for: "",
  date_range_for_ref: "",
  from_date: "",
  to_date: "",
  name: "",
  project_id: "",
  project_id_ref: "",
  status: [],
  status_ref: [],
};

export const commonTodoFilterFormKeys = {
  title: "",
  assigned_to: "",
  assigned_to_ref: "",
  status: "",
  status_ref: "",
  team_id: "",
  team_id_ref: "",
  date_range: "",
  from_date: "",
  to_date: "",
};

export const commonProjectFilterFormKeys = {
  date_range: "",
  date_range_for: "",
  date_range_for_ref: "",
  from_date: "",
  to_date: "",
  title: "",
  project_id: "",
  project_id_ref: "",
  account_id: "",
  account_id_ref: "",
  status: "",
  status_ref: "",
  product_id: [],
  product_id_ref: [],
};

export const commonAnnouncementFilterFormKeys = {
  title: "",
  date_range: "",
  date_range_for: "",
  from_date: "",
  to_date: "",
  inactive: "",
};

export const commonLabelsFilterFormKeys = {
  label: "",
  subject_type: "",
  subject_type_ref: "",
};

export const commonProductsFilterFormKeys = {
  name: "",
};

export const commonInvitationFilterFormKeys = {
  date_range: "",
  date_range_for: "",
  from_date: "",
  to_date: "",
  to_email: "",
  role_id: "",
  role_id_ref: "",
  expiring_time: "",
};

export const reminderFormKeys = {
  entity_type: "",
  entity_id: "",
  user_id: "",
  frequency: "",
  interval: "",
  remarks: "",
  reminder_date_time: getFormattedEndDateTime(
    null,
    "YYYY-MM-DD HH:mm:ss",
    "minutes",
    30
  ),
  time_zone: "",
  time_zone_ref: { label: "", value: "" },
  is_repeating: 0,
  description: "",
  is_dashboard_reminder: 0,
};

export const emailComposeFormKeys = {
  title: "",
  content: "",
  from_email: "",
  to_email: "",
  cc_email: "",
  bcc_email: "",
  subject: "",
  attachments: [],
  file_urls: [],
};

export const userFormKeys = {
  first_name: "",
  last_name: "",
  email: "",
  username: "",
  password: "",
  c_password: "",
  phone: "",
  role_id: "",
  role_id_ref: "",
  branch_id: "",
  branch_id_ref: "",
  company_id: "",
  company_id_ref: { label: "", value: "" },
  language: "",
  language_ref: {
    label: "",
    value: "",
  },
  image: "",
  time_zone: "",
  time_zone_ref: { label: "", value: "" },
};

export const commonEmailFilterFormKeys = {
  date_range: "",
  date_range_for: "",
  from_date: "",
  to_date: "",
  to_email: "",
  from_email: "",
  read: "",
  starred: "",
  start_date_range: "",
};

export const userResetPasswordFormKeys = {
  old_password: "",
  new_password: "",
  c_password: "",
};

export const rolesFormKeys = {
  name: "",
  description: "",
  is_active: 1,
};

export const projectReportFilterFormKeys = {
  from_date: "",
  to_date: "",
  date_range: "",
  date_range_for: "",
  project_id: "",
  project_id_ref: { label: "", value: "" },
  customer_id: "",
  customer_id_ref: { label: "", value: "" },
  status: "",
  status_ref: { label: "", value: "" },
};

export const taskDetailedReportFilterFormKeys = {
  date_range: "",
  from_date: "",
  to_date: "",
  date_range_for: "",
  customer_id: "",
  customer_id_ref: { label: "", value: "" },
  project_id: "",
  project_id_ref: { label: "", value: "" },
  assigned_to: [],
  assigned_to_ref: [],
  status: "",
  status_ref: { label: "", value: "" },
};

export const emailConfigFilterFormKeys = {
  email: "",
  connection_type: "",
};

export const emailConfigFormKeys = {
  host: "",
  port: "",
  encryption: "",
  validate_certificate: 0,
  username: "",
  password: "",
  email: "",
  connection_type: "outgoing",
  mailer: "",
};

export const generativeAiFormKeys = {
  prompt: "",
  type: "task-generator",
  response: "",
};

export const sprintDetailedReportFilterFormKeys = {
  date_range: "",
  from_date: "",
  to_date: "",
  date_range_for: "",
  project_id: "",
  project_id_ref: { label: "", value: "" },
  status: "",
  status_ref: { label: "", value: "" },
};

export const teamsFilterFormKeys = {
  name: "",
  team_lead_id: "",
  team_lead_id_ref: { label: "", value: "" },
};

export const documentTypeFilterFormKeys = {
  name: "",
  type: "",
  days_until_expiry: "",
  reminder_pattern: "",
};

export const documentTypeFormKeys = {
  name: "",
  type: "",
  days_until_expiry: "",
  reminder_pattern: "",
};

export const resetCustomerPasswordFormKeys = {
  old_password: "",
  new_password: "",
  c_password: "",
};