import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, Col, Row } from "react-bootstrap";
import BasicECharts from "components/common/BasicEChart";
import * as echarts from "echarts/core";
import ListLoading from "module/common/ListLoading";
import useAxisproTranslate from "hooks/useAxisproTranslate";

const TicketSingleItem = ({
  percentage = 0,
  count,
  title,
  singleData,
  graphData,
  isLoading,
}) => {
  const Translate = useAxisproTranslate();
  const getOptions = (data) => ({
    tooltip: {
      trigger: "axis",
      formatter: "{b0} : {c0}",
    },
    xAxis: {
      data: ["Week 1", "Week 2", "Week 3", "Week 4", "Week 5", "Week 6"],
    },
    series: [
      {
        type: "line",
        data: graphData,
        color: singleData.chartColor,
        smooth: true,
        lineStyle: {
          width: 2,
        },
        areaStyle: {
          color: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color:
                  singleData.chartColor === "#2c7be5"
                    ? "rgba(44, 123, 229, .25)"
                    : singleData.chartColor === "#00d27a"
                    ? "rgba(0, 210, 122, .25)"
                    : singleData.chartColor === "#27bcfd"
                    ? "rgba(39, 188, 253, .25)"
                    : "rgba(245, 128, 62, .25)",
              },
              {
                offset: 1,
                color:
                  singleData.chartColor === "#2c7be5"
                    ? "rgba(44, 123, 229, 0)"
                    : singleData.chartColor === "#00d27a"
                    ? "rgba(0, 210, 122, 0)"
                    : singleData.chartColor === "#27bcfd"
                    ? "rgba(39, 188, 253, 0)"
                    : "rgba(245, 128, 62, 0)",
              },
            ],
          },
        },
      },
    ],
    grid: {
      bottom: "2%",
      top: "2%",
      right: "0",
      left: "0px",
    },
  });

  return (
    <>
      {isLoading ? (
        <ListLoading style={{ maxHeight: "11vh", overflow: "hidden" }} />
      ) : (
        <Row className="g-0">
          <Col md={12} className={singleData.className}>
            <Row className="g-0">
              <Col xs={6}>
                <img src={singleData.img} alt="" width="39" className="mt-1" />
                <h2 className="mt-2 mb-1 text-700 fw-normal">
                  {count}
                  <Badge
                    pill
                    bg="transparent"
                    className={`text-${singleData.color} fs-10 px-2`}
                  >
                    <FontAwesomeIcon
                      icon={
                        parseFloat(percentage) === 0
                          ? null
                          : parseFloat(percentage) < 0
                          ? "caret-down"
                          : "caret-up"
                      }
                      className="me-1"
                    />
                    {percentage}%
                  </Badge>
                </h2>
                <h6 className="mb-0 fw-semi-bold fs--4">{Translate(title)}</h6>
              </Col>
              <Col xs={6} className="d-flex align-items-center px-0">
                <BasicECharts
                  echarts={echarts}
                  options={getOptions(graphData)}
                  className="w-100 h-50"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
};

TicketSingleItem.propTypes = {
  percentage: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  title: PropTypes.string.isRequired,
  singleData: PropTypes.object.isRequired,
  graphData: PropTypes.array.isRequired,
};

export default TicketSingleItem;
