import { React, useContext } from "react";
import { Col, Row } from "react-bootstrap";
import CountCustRevenue from "./CountCustRevenue";
import corner1 from "assets/img/icons/spot-illustrations/corner-1.png";
import corner2 from "assets/img/icons/spot-illustrations/corner-2.png";
import { AuthWizardContext } from "context/Context";

function TotalCustomerRevenueTile({ data, isLoading }) {
  const { user } = useContext(AuthWizardContext);
  return (
    <div className="d-flex flex-column gap-3" style={{ height: "100%" }}>
      <Row className="g-2" style={{ height: "100%" }}>
        <Col md={12} xxl={12}>
          <CountCustRevenue
            data={`${data.client_count}`}
            isLoading={isLoading}
            label={"Total Customers"}
            color={"#E6854D"}
            route={"/account/grid"}
            btn="See all"
            background={corner1}
          />
        </Col>
      </Row>

      <Row className="g-2" style={{ height: "100%" }}>
        <Col md={12} xxl={12}>
          <CountCustRevenue
            data={"0"}
            rightText={user?.branch?.base_currency ?? ""}
            isLoading={isLoading}
            label={"Total Revenue"}
            color={"#59BBF7"}
            route={"/reports"}
            btn="See all closings"
            background={corner2}
          />
        </Col>
      </Row>
    </div>
  );
}

export default TotalCustomerRevenueTile;
