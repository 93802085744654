import { React, useEffect, useState } from "react";
import {
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
  useSearchParams,
} from "react-router-dom";
import axios from "axios";
import { Offcanvas, Form } from "react-bootstrap";
import { apiCall } from "helpers/apiCalls";
import { showToast } from "module/common/Toast/toast";
import { SaveButton } from "module/common/Buttons/CommonButton";
import removeRefData from "helpers/removeRefData";
import BackButton from "components/common/BackButton";
import { todoFormKeys } from "helpers/formKeys";
import SelectTodoStatus from "components/form/SelectTodoStatus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ListLoading from "module/common/ListLoading";
import SelectUsers from "components/form/SelectUsers";
import { CustomBackButton } from "module/common/Buttons/AppButtons";
import SelectTeams from "components/form/SelectTeams";
import AppDatePicker from "components/app-date-picker/AppDatePicker";

const TodoForm = ({ show = true, id = "", fetchData, onHide }) => {
  const [getData] = useOutletContext() || [{}];
  let navigate = useNavigate();
  let { itemId } = useParams();
  itemId = id ? id : itemId;
  const [queryParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [onSave, setOnSave] = useState(false);
  const [formData, setFormData] = useState(todoFormKeys);
  const [formError, setFormError] = useState({});
  const pageNumber = queryParams.get("page");
  const { overviewId } = useParams();
  const allQueryParams = Object.fromEntries([...queryParams]);
  const { title, status } = allQueryParams;
  const isParamsAvailable = title || status;

  const locationPathName = useLocation();
  const mode =
    locationPathName?.pathname?.includes("/add") ||
    locationPathName?.pathname?.includes("/edit") ||
    show;
  const handleFieldChange = (e, action) => {
    let newFormData;
    let value;
    if (!action) {
      if (e.target.name === "status") {
        value = e.target.value;
      } else {
        value = e.target.value;
      }
      newFormData = {
        ...formData,
        [e.target.name]: value,
      };
    } else {
      if (action.action === "select-option") {
        value = e.code ? e.code : e.value;
      }
      newFormData = {
        ...formData,
        [action.name]: value,
        [action.name + "_ref"]: e,
      };
    }
    setFormData(newFormData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (onSave) return;
    setFormError({});
    setOnSave(true);

    let formDataWithoutRefkeys = removeRefData(formData);

    axios({
      method: itemId ? "put" : "post",
      url: `crm/crm-todo${itemId ? "/" + itemId : ""}`,
      data: formDataWithoutRefkeys,
    })
      .then((response) => {
        if (response?.data?.success === true) {
          setFormData(todoFormKeys);
          showToast(response?.data?.message, "success");
          typeof onHide === "function"
            ? onHide()
            : navigate(itemId || overviewId ? -1 : `/todo`);
          typeof fetchData === "function"
            ? fetchData()
            : (!isParamsAvailable || itemId) &&
              getData(itemId ? pageNumber : 1);
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
      })
      .catch((error) => {
        if (error?.response?.data && error?.response?.data?.message) {
          const validation_error =
            error?.response?.data &&
            error?.response?.data?.data &&
            error?.response?.data?.data?.errors
              ? error?.response?.data?.data?.errors
              : null;
          validation_error && setFormError({ ...validation_error });
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
        setOnSave(false);
      });
  };

  useEffect(() => {
    if (itemId) {
      getDataItem();
    }
    return () => {
      setOnSave(false);
      setFormData(todoFormKeys);
      setFormError({});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  const getDataItem = async (page) => {
    setLoading(true);
    const data = await apiCall({
      url: "crm/crm-todo/" + itemId,
      params: {
        page: page,
      },
    });
    setFormData({
      title: data?.title,
      description: data?.description,
      label: data?.label,
      assigned_to: data?.assigned_to,
      assigned_to_ref: {
        label: data?.assigned_to_user_full_name,
        value: data?.assigned_to,
      },
      status: data?.status,
      status_ref: {
        label: data?.status?.replace("_", " "),
        value: data?.status,
      },
      team_id: data?.team?.id,
      team_id_ref: { label: data?.team?.name, value: data?.team?.id },
      due_date: data?.due_date,
    });
    setLoading(false);
  };

  return (
    <Offcanvas show={show} onHide={onHide} placement="end">
      <Offcanvas.Header className="d-flex flex-row justify-content-between border-bottom gap-1">
        <div className="d-flex align-items-center pt-1 pb-1">
          <FontAwesomeIcon
            icon="list-check"
            size={"lg"}
            className="me-2 text-dark"
          />
          <span
            className="custom-module-header text-uppercase"
            style={{ fontSize: 16 }}
          >
            {itemId ? "Update Todo" : "Create Todo"}
          </span>
        </div>
        <CustomBackButton
          variant="falcon-default"
          title="Close"
          onClick={() => {
            if (typeof onHide === "function") {
              onHide();
            } else {
              navigate(-1);
            }
          }}
        />
      </Offcanvas.Header>
      <Offcanvas.Body
        style={{
          padding: "20px",
          backgroundColor: "#fff",
          borderRadius: "5px",
        }}
      >
        {!loading ? (
          <Form onSubmit={handleSubmit} className="h-100 d-flex flex-column">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label
                className="require-data"
                style={{ fontSize: "13px", marginBottom: "10px" }}
              >
                Title
              </Form.Label>
              <Form.Control
                type="text"
                name="title"
                onChange={handleFieldChange}
                value={formData?.title}
                placeholder="Enter Title"
                isInvalid={!!formError?.title}
              />
              <Form.Control.Feedback type="invalid">
                {formError?.title}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label
                className="require-data"
                style={{ fontSize: "13px", marginBottom: "10px" }}
              >
                Description
              </Form.Label>

              <Form.Control
                as="textarea"
                rows={3}
                name="description"
                onChange={handleFieldChange}
                value={formData?.description}
                placeholder="Enter Description"
                isInvalid={!!formError?.description}
              />
              <Form.Control.Feedback type="invalid">
                {formError?.description}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
              <Form.Label>Assigned To</Form.Label>
              <SelectUsers
                value={formData?.assigned_to_ref}
                name="assigned_to"
                error={formError?.assigned_to}
                handleFieldChange={handleFieldChange}
              />
              <Form.Control.Feedback type="invalid">
                {formError?.assigned_to}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Due Date</Form.Label>
              <AppDatePicker
                name="due_date"
                value={formData.due_date}
                yearPlaceholder="yyyy"
                monthPlaceholder="mm"
                dayPlaceholder="dd"
                onChange={handleFieldChange}
              />
              <Form.Control.Feedback type="is-invalid">
                {formError.due_date}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label style={{ fontSize: "13px", marginBottom: "10px" }}>
                Label
              </Form.Label>
              <Form.Control
                type="text"
                name="label"
                onChange={handleFieldChange}
                value={formData?.label}
                placeholder="Enter Label"
                isInvalid={!!formError?.label}
              />
              <Form.Control.Feedback type="invalid">
                {formError?.label}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label style={{ fontSize: "13px", marginBottom: "10px" }}>
                Team
              </Form.Label>
              <SelectTeams
                value={formData?.team_id_ref}
                name="team_id"
                error={formError?.team_id}
                handleFieldChange={handleFieldChange}
              />
              <Form.Control.Feedback type="invalid">
                {formError?.team_id}
              </Form.Control.Feedback>
            </Form.Group>
            {itemId && (
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label style={{ fontSize: "13px", marginBottom: "10px" }}>
                  Status
                </Form.Label>
                <SelectTodoStatus
                  value={formData?.status_ref}
                  name="status"
                  handleFieldChange={handleFieldChange}
                  error={formError?.status}
                />
                <Form.Control.Feedback type="invalid">
                  {formError?.status}
                </Form.Control.Feedback>
              </Form.Group>
            )}
            <div className="d-flex gap-1 mt-auto">
              <SaveButton
                handleSubmit={handleSubmit}
                onSave={onSave}
                id={itemId}
              />
              {!onSave && (
                <BackButton variant={"danger"} size={"sm"}>
                  Cancel
                </BackButton>
              )}
            </div>
          </Form>
        ) : (
          <ListLoading />
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default TodoForm;
