import React from "react";
import { Link } from "react-router-dom";
import { Card, Col, Row } from "react-bootstrap";
import CountUp from "react-countup";
import PropTypes from "prop-types";
import DashboardTileLoader from "module/common/Loading/DashboardTileLoader";
import NothingToShow from "../NothingToShow";
import useAxisproTranslate from "hooks/useAxisproTranslate";

function CountCustRevenue({
  isLoading,
  label,
  route,
  data,
  color,
  background,
  btn,
  rightText,
}) {
  const Translate = useAxisproTranslate();
  return (
    <Card
      className="card-main"
      style={{
        height: "100%",
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      {!isLoading ? (
        <>
          {data && data !== 0 ? (
            <Card.Body>
              <div className="mt-2 mb-0">
                <Row className="g-2 h-100 align-items-end">
                  <Col sm={12} md={12}>
                    <div className="d-flex flex-column">
                      <h6 className="fw-bold fs-0 p-0">{Translate(label)}</h6>
                    </div>
                    <br />
                    <div
                      className="mb-2 fw-normal"
                      style={{ color: `${color}`, fontSize: "40px" }}
                    >
                      <CountUp
                        start={0}
                        end={data}
                        duration={2.75}
                        suffix={""}
                        prefix={""}
                        separator=","
                        decimals={""}
                        decimal="."
                      />
                      {rightText && (
                        <span
                          style={{ fontSize: "20px" }}
                        >{` ${rightText}`}</span>
                      )}
                    </div>
                    <br />
                    <Link to={route} className="text-primary fw-bold mt-2">
                      {btn}
                    </Link>
                  </Col>
                </Row>
              </div>
            </Card.Body>
          ) : (
            <div className="mt-3 h-100">
              <NothingToShow />
            </div>
          )}
        </>
      ) : (
        <Card.Body className="p-2">
          <DashboardTileLoader column={1} />
        </Card.Body>
      )}
    </Card>
  );
}

CountCustRevenue.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  label: PropTypes.string,
  route: PropTypes.string,
  data: PropTypes.any,
  rightText: PropTypes.string,
};

export default CountCustRevenue;
