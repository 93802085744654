import React from "react";
import Flex from "components/common/Flex";
import CardDropdown from "components/common/CardDropdown";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import IconItem from "components/common/icon/IconItem";
import classNames from "classnames";
import { GetAcodaxPermission } from "module/common/Permissions/AcodaxPermission";

function TodoListItem({ item, index, handleChange, color, handleAction }) {
  const onButtonClick = (action) => {
    handleAction(action, item?.id);
  };
  const permission = {
    show: GetAcodaxPermission("TODO", "show"),
    delete: GetAcodaxPermission("TODO", "delete"),
    update: GetAcodaxPermission("TODO", "update"),
    store: GetAcodaxPermission("TODO", "store"),
  };
  return (
    <Flex
      justifyContent="between"
      className="hover-actions-trigger btn-reveal-trigger px-x1 hover-bg-100 border-200 border-top"
      alignItems="center"
    >
      <Form.Check
        className="py-1 my-2 d-flex align-items-center ms-3"
        id={`item.id`}
      >
        <Form.Check.Input
          type="checkbox"
          defaultChecked={item.completed}
          onChange={(e) => handleChange(item.id, e.target.checked)}
          className={classNames(
            `mt-0 rounded-circle form-check-input-${color}`,
            {
              "form-check-line-through": item.completed,
            }
          )}
          style={{ borderColor: color }}
        />
        <Form.Check.Label className="flex-1 mb-0 ms-3">
          {item.title}
        </Form.Check.Label>
      </Form.Check>
      <div className="d-flex align-items-center">
        <div className="hover-actions">
          <IconItem
            tag="button"
            icon="clock"
            size="sm"
            title="Set Reminder"
            className="btn rounded-3 me-2 fs-11"
            onClick={() => onButtonClick("clock")}
          />
          {permission?.update && (
            <IconItem
              tag="button"
              icon="edit"
              size="sm"
              title="Edit"
              className="btn rounded-3 fs-11"
              onClick={() => onButtonClick("edit")}
            />
          )}
        </div>
        {permission?.show && permission?.delete && (
          <CardDropdown
            btnRevealClass="btn-reveal-sm "
            drop={index > 1 ? "start" : undefined}
            handleAction={onButtonClick}
            permission={permission}
          />
        )}
      </div>
    </Flex>
  );
}
TodoListItem.propTypes = {
  item: PropTypes.shape({
    task: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    completed: PropTypes.bool,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }),
  length: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default TodoListItem;
