import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { stringLimiter } from "helpers/utils";

const SingleActivity = ({ title, text, icon, status, time, isLast }) => {
  return (
    <Row
      className={classNames(
        "g-3 recent-activity-timeline recent-activity-timeline-primary",
        {
          "pb-x1": !isLast,
          "recent-activity-timeline-past": status === "completed",
          "recent-activity-timeline-current": status === "current",
        }
      )}
    >
      <Col xs="auto" className="ps-4 ms-2">
        <div className="ps-2">
          <div className="icon-item icon-item-sm rounded-circle bg-200 shadow-none">
            <FontAwesomeIcon icon={icon} className="text-primary" />
          </div>
        </div>
      </Col>
      <Col>
        <Row className={classNames("g-3 ", { "border-bottom pb-x1": !isLast })}>
          <Col className="py-2">
            <h6 className="text-800 mb-1 text-lowercase">
              {stringLimiter(title, 40, "-")}
            </h6>
            <p className="fs--2 text-600 mb-0 text-lowercase">
              {stringLimiter(text, 30)}
            </p>
          </Col>
          <Col xs="auto">
            <p className="fs-11 text-500 mb-0">{time}</p>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
SingleActivity.propTypes = {
  activity: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
    time: PropTypes.string.isRequired,
    status: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  }),
  isLast: PropTypes.bool,
};
export default SingleActivity;
