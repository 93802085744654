export const recentActivities = [
  {
    id: 0,
    icon: "envelope",
    status: "completed",
  },
  {
    id: 1,
    icon: "archive",
    status: "completed",
  },
  {
    id: 2,
    icon: "code",
    status: "current",
  },
  {
    id: 3,
    icon: "code-branch",
    status: false,
  },
  {
    id: 4,
    icon: ["far", "file-code"],
    status: false,
  },
];
