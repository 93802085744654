import React from "react";
import { Card } from "react-bootstrap";
import FalconCardHeader from "components/common/FalconCardHeader";
import DealStorageFunnelChart from "./DealStorageFunnelChart";
import Flex from "components/common/Flex";
import FalconLink from "components/common/FalconLink";
import useAxisproTranslate from "hooks/useAxisproTranslate";

const DealStorageFunnel = () => {
  const Translate = useAxisproTranslate();
  return (
    <Card className="card-main" style={{ height: "100%" }}>
      <FalconCardHeader
        title="Deal Storage Funnel"
        titleTag="h6"
        titleClass="fw-bold fs-0"
        className="border-200 border-bottom py-2 "
        endEl={
          <FalconLink
            to="/opportunity"
            title="View Details"
            className="px-0 fs--1"
          />
        }
      />
      <Card.Body dir="ltr">
        <Flex
          justifyContent="between"
          alignItems="center"
          className="rtl-row-reverse"
        >
          <h6 className="fs--1 text-500 flex-1">{Translate("Deal Stage")}</h6>
          {/* <h6 className="fs--1 text-500 mx-2">{Translate("Count of Deals")}</h6> */}
          <h6 className="fs--1 text-500">{Translate("Conversion")}</h6>
        </Flex>
        <DealStorageFunnelChart />
      </Card.Body>
    </Card>
  );
};

export default DealStorageFunnel;
