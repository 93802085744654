import React from "react";
import { Card } from "react-bootstrap";
import FalconCardHeader from "components/common/FalconCardHeader";
import RevenueForecastChart from "./RevenueForecastChart";
const RevenueForecast = () => {
  return (
    <Card className="h-100 card-main">
      <FalconCardHeader
        title="Lead V/S Opportunity"
        titleTag="h6"
        titleClass="fw-bold fs-0 mt-1"
      />
      <Card.Body>
        <RevenueForecastChart />
      </Card.Body>
    </Card>
  );
};

export default RevenueForecast;
