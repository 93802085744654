import React from "react";
import PropTypes from "prop-types";

import * as echarts from "echarts/core";
import ReactEChartsCore from "echarts-for-react/lib/core";
import { LineChart } from "echarts/charts";
import { getColor } from "helpers/utils";
import {
  GridComponent,
  TooltipComponent,
  TitleComponent,
  LegendComponent,
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  CanvasRenderer,
  LegendComponent,
]);

const tooltipFormatter = (params) => {
  return `<div class="card">
            <div class="card-body py-2">
              <h6 class="text-600 fw-normal mt-2">
                <span class="fw-medium">${params[0].data}</span>
              </h6>
            </div>
          </div>`;
};

const getOptions = (data1, data2) => ({
  color: getColor("white"),
  tooltip: {
    trigger: "axis",
    padding: 0,
    backgroundColor: "transparent",
    borderWidth: 0,
    transitionDuration: 0,
    axisPointer: {
      type: "none",
    },
    formatter: tooltipFormatter,
  },
  xAxis: {
    type: "category",
    data: data1,
    axisLabel: {
      color: "#000000",
      fontSize: 12,
    },
    axisLine: {
      lineStyle: {
        color: "#cccccc",
      },
    },
  },
  yAxis: {
    type: "value",
    position: "right",
    min: 0,
    interval: 1,
    axisLabel: {
      color: "#000000",
      fontSize: 12,
    },
    axisLine: {
      lineStyle: {
        color: "#cccccc",
      },
    },
    splitLine: {
      lineStyle: {
        color: "#555555",
        type: "dashed",
      },
    },
  },
  grid: {
    top: "5%",
    left: "0%",
    right: "5%",
    bottom: "12%",
  },
  series: [
    {
      type: "line",
      data: data2,
      symbolSize: 0,
      boundaryGap: false,
      lineStyle: {
        color: "#3a84e9",
        width: 2,
      },
      areaStyle: {
        color: "rgba(58, 132, 233, 0.2)",
      },
      itemStyle: {
        color: "#3a84e9",
      },
    },
  ],
});

const SalesChart = ({ data }) => {
  return (
    <ReactEChartsCore
      echarts={echarts}
      option={getOptions(data[0], data[1])}
      style={{ height: "12.6rem" }}
    />
  );
};

SalesChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.array).isRequired,
};

export default SalesChart;
