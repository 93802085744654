import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Card } from "react-bootstrap";
import FalconCardHeader from "components/common/FalconCardHeader";
import SimpleBarReact from "simplebar-react";
import SingleActivity from "./SingleActivity";
import { apiCall } from "helpers/apiCalls";
import { recentActivities } from "./RecentActivityData";
import ListLoading from "module/common/ListLoading";
import NothingToShow from "../NothingToShow";
import {
  getFormattedCurrentDateTime,
  getFormattedEndDateTime,
} from "helpers/utils";

const RecentActivityTile = () => {
  const [item, setItem] = useState([]);
  const maxNo = 5;
  const [isLoading, setIsLoading] = useState(false);
  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await apiCall({
        url: "activity-log",
        params: {
          from_date: getFormattedCurrentDateTime("YYYY-MM-DD"),
          to_date: getFormattedEndDateTime(null, "YYYY-MM-DD", "days", 6),
        },
      });
      setItem(response?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const activitiesToShow = item.length === 1 ? item : item.slice(1, maxNo);

  return (
    <>
      <Card className="h-100 card-main">
        <FalconCardHeader
          title="Recent Activity"
          titleTag="h6"
          titleClass="fw-bold fs-0 mt-1"
        />
        <SimpleBarReact style={{ height: "26rem" }}>
          {isLoading ? (
            <div className="p-3">
              <ListLoading style={{ maxHeight: "40vh", overflow: "hidden" }} />
            </div>
          ) : (
            <>
              {item?.length > 0 ? (
                <Card.Body className="ps-2 recent-activity-body-height">
                  {activitiesToShow.map((data, index) => (
                    <SingleActivity
                      key={index}
                      title={data.description}
                      text={data.causer_name}
                      time={data.created_at_formatted}
                      isLast={index === activitiesToShow.length - 1}
                      icon={recentActivities[index]?.icon}
                      status={recentActivities[index]?.status}
                    />
                  ))}
                </Card.Body>
              ) : (
                <div style={{ height: "26rem" }}>
                  <NothingToShow />
                </div>
              )}
            </>
          )}
        </SimpleBarReact>
      </Card>
    </>
  );
};

RecentActivityTile.propTypes = {
  data: PropTypes.arrayOf(SingleActivity.propTypes.activity),
};

export default RecentActivityTile;
