import ReactEChartsCore from "echarts-for-react/lib/core";
import merge from "lodash.merge";
import PropTypes from "prop-types";
import React from "react";

const getOption = () => ({
  color: "#3f7cdd",
  // tooltip: {
  //   trigger: "item",
  //   axisPointer: {
  //     type: "none",
  //   },
  //   padding: [7, 10],
  //   backgroundColor: "gray",
  //   borderColor: "blue",
  //   textStyle: { color: "blue" },
  //   borderWidth: 1,
  //   transitionDuration: 0,
  // },
  xAxis: {
    type: "category",
    show: false,
    boundaryGap: false,
  },
  yAxis: {
    show: false,
    type: "value",
    boundaryGap: false,
  },
  series: [
    {
      type: "line",
      symbol: "none",
    },
  ],
  grid: { right: "0", left: "0", bottom: "0", top: "0" },
});

const TotalTaskChart = ({ echarts, options, ...rest }) => {
  return (
    <ReactEChartsCore
      echarts={echarts}
      option={merge(getOption("blue"), options)}
      {...rest}
    />
  );
};

TotalTaskChart.propTypes = {
  echarts: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
};

export default TotalTaskChart;
