import React, { useContext, useEffect, useState } from "react";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import qs from "qs";
import PropTypes from "prop-types";
import { GRID, LIST } from "./View/constants";
import { apiCall } from "helpers/apiCalls";
import { DeleteContext } from "context/Context";
import {
  getObjectFromLocalStorage,
  setObjectInLocalStorage,
} from "helpers/utils.js";
import Grid from "module/common/ViewPages/Common/Grid/Grid";
import List from "./View/List";
import ProjectTableHeader from "./ProjectTableHeader";

function ProjectTable({ projectId, openFrom, renderFromId }) {
  let navigate = useNavigate();
  const { dispatch } = useContext(DeleteContext);
  const [queryParams] = useSearchParams();
  const allQueryParams = Object.fromEntries([...queryParams]);
  const [component, setComponent] = useState(GRID);
  const activeView = queryParams.get("view");
  const [isLoading, setIsLoading] = useState(false);
  const [projects, setProjects] = useState({});
  const {
    from_date,
    to_date,
    date_range_for,
    project_id,
    title,
    account_id,
    status,
    product_id,
    refresh,
  } = allQueryParams;
  const pageNumber = queryParams.get("page");
  const isFromAccount = openFrom === "account";
  const isFromEmployee = openFrom === "employee";
  const userBasedFilterVisibility =
    getObjectFromLocalStorage("filter")?.["projectFilter"] ?? false;
  const [filterVisibility, setFilterVisibility] = useState(
    userBasedFilterVisibility
  );

  const handleDelete = (id) => {
    dispatch({
      type: "CONFIG",
      payload: {
        target: id,
        url: "/crm/project/" + id,
        title: "Delete Project",
        message: "Are you sure you want to delete this project?",
        onSuccess: async () => {
          const dataWithoutDeletedItem = projects?.data?.filter(
            (account) => account.id !== id
          );
          if (dataWithoutDeletedItem.length < 1 && pageNumber > 1) {
            // if data length < 1 and page number > 1 then redirect to previous page
            navigate(
              "/project?" +
                createSearchParams({ ...allQueryParams, page: pageNumber - 1 })
            );
          } else if (dataWithoutDeletedItem.length === 0 && pageNumber < 1) {
            getData();
          } else {
            setProjects((previous) => ({
              ...previous,
              data: dataWithoutDeletedItem,
            }));
            // reset deleteContext
            dispatch({
              type: "RESET",
            });
          }
        },
      },
    });
  };

  const getData = async (page = 1) => {
    setIsLoading(true);
    const productIdFilter = product_id ? product_id?.split("/") : [];

    const params = {
      page: page,
      requested_from: "project_list",
      from_date: from_date,
      to_date: to_date,
      date_range_for: date_range_for,
      title: title,
      project_id: project_id,
      account_id: account_id,
      status: status,
      product_id: productIdFilter,
    };
    params.requested_from = openFrom;
    if (isFromAccount) {
      params.account_id = renderFromId;
    } else if (isFromEmployee) {
      params.employee_id = renderFromId;
    }
    try {
      const data = await apiCall({
        url: "crm/project",
        params: params,
        paramsSerializer: (params) => {
          return qs.stringify(params);
        },
      });
      setProjects(data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData(pageNumber);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pageNumber,
    from_date,
    to_date,
    date_range_for,
    title,
    project_id,
    account_id,
    status,
    product_id,
    refresh,
  ]);

  useEffect(() => {
    if (activeView === LIST) {
      setComponent(LIST);
    } else {
      setComponent(GRID);
    }
  }, [activeView]);

  const ComponentToRender = {
    [GRID]: Grid,
    [LIST]: List,
  }[component];

  useEffect(() => {
    const existingFilter = getObjectFromLocalStorage("filter") || {};
    const updatedFilter = {
      ...existingFilter,
      projectFilter: filterVisibility,
    };

    setObjectInLocalStorage("filter", updatedFilter);
  }, [filterVisibility]);

  return (
    <ProjectTableHeader
      getData={getData}
      loading={isLoading}
      openFrom={openFrom}
      filterVisibility={filterVisibility}
      setFilterVisibility={setFilterVisibility}
    >
      <ComponentToRender
        projectId={projectId}
        getData={getData}
        data={projects}
        allQueryParams={allQueryParams}
        handleDelete={handleDelete}
        renderFrom={"project"}
        openFrom={openFrom}
        filterVisibility={filterVisibility}
      />
    </ProjectTableHeader>
  );
}

ProjectTable.propTypes = {
  projectId: PropTypes.string,
};

export default ProjectTable;
