import React from "react";
import { Doughnut } from "react-chartjs-2";

const RunningTaskChart = ({ runningTaskCount, totalTaskCount }) => {
  const options = {
    rotation: -90,
    circumference: "180",
    cutout: "80%",
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const data = {
    labels: ["Running Task", "Total Task"],
    datasets: [
      {
        data: [runningTaskCount, totalTaskCount],
        backgroundColor: ["#407cde", "#dae2ee"],
        borderColor: ["#407cde", "#dae2ee"],
      },
    ],
  };
  return <Doughnut data={data} options={options} width="119" />;
};

export default RunningTaskChart;
