import holdTicket from "assets/img/support-desk/hold-tickets.png";
import openTicket from "assets/img/support-desk/open-tickets.png";
import dueTicket from "assets/img/support-desk/due-tickets.png";
import unassigned from "assets/img/support-desk/unassigned.png";
export const statusData = [
  {
    color: "primary",
    img: holdTicket,
    chartColor: "#2c7be5",
    className:
      "border-200 border-md-200 border-bottom border-md-end pb-x1 py-2 pe-md-x1",
  },
  {
    color: "success",
    img: openTicket,
    title: "Open the ticket",
    chartColor: "#00d27a",
    className: "ps-md-x1 pb-x1 pt-x1 pt-md-0 border-bottom border-200 py-2",
  },
  {
    color: "info",
    img: dueTicket,
    chartColor: "#27bcfd",
    className:
      "border-md-200 border-200 border-bottom border-md-bottom-0 border-md-end pt-x1 pe-md-x1 pb-x1 pb-md-0 py-2",
  },
  {
    color: "warning",
    img: unassigned,
    chartColor: "#f5803e",
    className:
      "border-md-200 border-200 border-bottom border-md-bottom-0 border-md-end pt-x1 pe-md-x1 pb-x1 pb-md-0 py-2",
  },
];
