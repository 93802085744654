export const stats = [
  {
    chartData: [
      172, 129, 123, 158, 196, 106, 187, 198, 152, 175, 178, 165, 188, 139, 115,
      131, 143, 140, 112, 167, 180, 156, 121, 190, 100,
    ],
    grid: { right: "16px", left: "0", bottom: "0", top: "0" },
  },
  {
    chartData: [
      170, 156, 171, 193, 108, 178, 163, 175, 117, 123, 174, 199, 122, 111, 113,
      140, 192, 167, 186, 172, 131, 187, 135, 115, 118,
    ],
    grid: { right: "16px", left: "16px", bottom: "0", top: "0" },
  },
  {
    chartData: [
      199, 181, 155, 164, 108, 158, 117, 148, 121, 152, 189, 116, 111, 130, 113,
      171, 193, 104, 110, 153, 190, 162, 180, 114, 183,
    ],
    grid: { right: "0", left: "16px", bottom: "0", top: "0" },
  },
];
