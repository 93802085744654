import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Card, Row, Col } from "react-bootstrap";
import CrmStatsItem from "./CrmStatsItem";
import { apiCall } from "helpers/apiCalls";
import { CrmStatsData } from "./CrmStatsData";
import { useNavigate } from "react-router-dom";
import { GetAcodaxPermission } from "module/common/Permissions/AcodaxPermission";

const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const CrmStats = () => {
  const [item, setItem] = useState({});
  const [loading, setIsLoading] = useState(false);
  const [months, setMonths] = useState({
    currentMonth: "",
    previousMonth: "",
  });

  const navigate = useNavigate();
  const getFormattedDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    return `${year}-${month}`;
  };

  useEffect(() => {
    const fetchData = async () => {
      const currentDate = new Date();
      const currentMonth = getFormattedDate(currentDate);
      const currentMonthName = monthNames[currentDate.getMonth()];

      const previousDate = new Date();
      previousDate.setMonth(previousDate.getMonth() - 1);
      const previousMonth = getFormattedDate(previousDate);
      const previousMonthName = monthNames[previousDate.getMonth()];

      setMonths({
        currentMonth: currentMonthName,
        previousMonth: previousMonthName,
      });

      try {
        setIsLoading(true);
        const response = await apiCall({
          url: "crm/dashboard-monthly-comparison",
          params: {
            start_month_year: previousMonth,
            end_month_year: currentMonth,
          },
        });
        setItem(response);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  const monthlyPercentage = item.monthlyPercentages || {};
  const dailyCount = item.dailyCount || {};
  const CrmDataArray = Object.values(dailyCount.CrmContact || {}) || [];
  const CrmLeadDataArray = Object.values(dailyCount.CrmLead || {}) || [];
  const CrmOpportunityArray =
    Object.values(dailyCount.CrmOpportunity || {}) || [];
  const UserDataArray = Object.values(dailyCount.User || {}) || [];

  const handleAction = (action, title) => {
    const isShow = action === "view";
    if (isShow) {
      switch (title) {
        case "Crm Lead":
          navigate("/lead/list");
          break;
        case "Crm Opportunity":
          navigate("/opportunity");
          break;
        case "User":
          navigate("/system-admin/user-roles/users");
          break;
        default:
          break;
      }
    }
  };

  const permission = {
    showContact:
      GetAcodaxPermission("LEAD", "list") ||
      GetAcodaxPermission("OPPORTUNITY", "list"),
    showLead: GetAcodaxPermission("LEAD", "list"),
    showOpportunity: GetAcodaxPermission("OPPORTUNITY", "list"),
    showUser: GetAcodaxPermission("USER", "show"),
  };

  return (
    <>
      <Card className="mb-2 card-main h-100">
        <Card.Body>
          <Row className="mt-3">
            {CrmStatsData?.map((item, index) => {
              const titleKey = item.title
                .replace("Crm ", "")
                .replace(/\s/g, "");
              const hasPermission = permission[`show${titleKey}`];
              if (!hasPermission) {
                return null;
              }
              const monthlyAmount =
                monthlyPercentage[item.title.replace(/\s/g, "")] || 0;
              const monthlyAmountValue = Math.abs(
                parseFloat(
                  monthlyPercentage[item.title.replace(/\s/g, "")] || 0
                )
              );
              const caret = monthlyAmount < 0 ? "caret-down" : "caret-up";
              const caretColor = monthlyAmount < 0 ? "danger" : "success";

              return (
                <Col lg={3} key={index}>
                  <CrmStatsItem
                    title={item?.title}
                    heading={item?.heading}
                    target={`${months.previousMonth} vs ${months.currentMonth}`}
                    icon={item?.icon}
                    loading={loading}
                    caret={caret}
                    color={item?.color}
                    data={
                      item?.title === "Crm Contact"
                        ? CrmDataArray
                        : item?.title === "Crm Lead"
                        ? CrmLeadDataArray
                        : item?.title === "Crm Opportunity"
                        ? CrmOpportunityArray
                        : UserDataArray
                    }
                    amount={monthlyAmountValue}
                    caretColor={caretColor}
                    handleAction={handleAction}
                    permission={permission}
                  />
                </Col>
              );
            })}
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

CrmStats.propTypes = {
  stats: PropTypes.arrayOf(PropTypes.shape(CrmStatsItem.propTypes.stat)),
};

export default CrmStats;
