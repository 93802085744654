import React from "react";
import PropTypes from "prop-types";
import { Col, Card, Row } from "react-bootstrap";
import classNames from "classnames";
import useAxisproTranslate from "hooks/useAxisproTranslate";

const Title = ({ titleTag: TitleTag, className, breakPoint, children }) => (
  <TitleTag
    className={classNames(
      {
        "mb-0": !breakPoint,
        [`mb-${breakPoint}-0`]: !!breakPoint,
      },
      className
    )}
  >
    {children}
  </TitleTag>
);

const FalconCardHeader = ({
  title,
  titleTag,
  titleClass,
  className,
  light,
  breakPoint,
  endEl,
  children,
}) => {
  const Translate = useAxisproTranslate();
  return (
    <Card.Header
      className={classNames({ "bg-body-tertiary": light }, className)}
    >
      {endEl ? (
        <Row className="align-items-center">
          <Col>
            <Title
              breakPoint={breakPoint}
              titleTag={titleTag}
              className={titleClass}
            >
              {Translate(title)}
            </Title>
            {children}
          </Col>
          <Col
            {...{ [breakPoint ? breakPoint : "xs"]: "auto" }}
            className={`text${breakPoint ? `-${breakPoint}` : ""}-right`}
          >
            {endEl}
          </Col>
        </Row>
      ) : (
        <Title
          breakPoint={breakPoint}
          titleTag={titleTag}
          className={titleClass}
        >
          {Translate(
            title && title.length > 30 ? title.substring(0, 19) + "..." : title
          )}
        </Title>
      )}
    </Card.Header>
  );
};

Title.propTypes = {
  breakPoint: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", "xxl"]),
  titleTag: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  className: PropTypes.string,
  children: PropTypes.node,
};

Title.defaultProps = { titleTag: "h5" };

FalconCardHeader.propTypes = {
  title: PropTypes.node.isRequired,
  light: PropTypes.bool,
  breakPoint: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", "xxl"]),
  endEl: PropTypes.node,
  titleTag: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  titleClass: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
};

export default FalconCardHeader;
