import { faLightbulb, faSeedling } from "@fortawesome/free-solid-svg-icons";

export const CrmStatsData = [
  {
    id: 1,
    heading: "New Contacts",
    title: "Crm Contact",
    icon: "phone",
    color: "primary",
    caretColor: "success",
  },
  {
    id: 2,
    heading: "New Leads",
    title: "Crm Lead",
    icon: faSeedling,
    color: "info",
    caretColor: "success",
  },
  {
    id: 3,
    heading: "New Opportunities",
    title: "Crm Opportunity",
    icon: faLightbulb,
    caret: "caret-down",
    color: "success",
    caretColor: "danger",
  },
  {
    id: 4,
    heading: "New Users",
    title: "User",
    icon: "user",
    color: "danger",
    caretColor: "danger",
  },
];

export const crmDashboardColors = [
  "primary",
  "success",
  "warning",
  "danger",
  "info",
  "secondary",
];

export const crmDashboardBootstrapColors = [
  "#007bff",
  "#28a745",
  "#dc3545",
  "#ffc107",
  "#17a2b8",
  "#6c757d",
];
