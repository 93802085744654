import { React, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Card, ProgressBar } from "react-bootstrap";
import Flex from "components/common/Flex";
import Avatar, { AvatarGroup } from "components/common/Avatar";
import { Link } from "react-router-dom";
import axios from "axios";
import FalconCardFooterLink from "components/common/FalconCardFooterLink";
import FalconCardHeader from "components/common/FalconCardHeader";
import RunningProjectAdvanceTable from "./RunningProjectAdvanceTable";
import useAdvanceTable from "hooks/useAdvanceTable";
import ListLoading from "module/common/ListLoading";
import { crmDashboardColors } from "../CrmStats/CrmStatsData";
import { GetAcodaxPermission } from "module/common/Permissions/AcodaxPermission";
import ProjectOffcanvas from "module/Project/ProjectOffcanvas/ProjectOffcanvas";
import NothingToShow from "../NothingToShow";
import useAxisproTranslate from "hooks/useAxisproTranslate";

const RunningProjects = () => {
  const Translate = useAxisproTranslate();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const fetchData = () => {
    setIsLoading(true);
    axios
      .get("crm/project-detailed-report", {
        params: {
          is_dashboard: 1,
        },
      })
      .then((res) => {
        if (res.data.success === true) {
          setData(res.data.data.data);
        }
      })
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const permission = {
    show: GetAcodaxPermission("PROJECT", "show"),
  };

  const [projectInfo, setProjectInfo] = useState({
    show: false,
    id: "",
  });

  const handleShowProjectInfo = (id) => {
    setProjectInfo({
      show: true,
      id: id,
    });
  };

  const columns = [
    {
      accessorKey: "title",
      header: Translate("Projects"),
      meta: {
        headerProps: { className: "text-800 fw-semi-bold" },
      },
      cell: ({ row: { index, original } }) => {
        const color = crmDashboardColors[index % crmDashboardColors.length];

        const { project_title, customer_name, project_id } = original;
        return (
          <Flex alignItems="center" className="position-relative">
            <Avatar
              name={project_title.charAt(0)}
              width="60"
              alt=""
              mediaClass={`text-${color} bg-${color}-subtle fs-9`}
            />
            <div className="flex-1 ms-3">
              <h6 className="mb-0 fw-semibold">
                {permission?.show ? (
                  <Link
                    className="text-1100 stretched-link"
                    onClick={() => handleShowProjectInfo(project_id)}
                  >
                    {project_title}
                  </Link>
                ) : (
                  <p className="fs--2 mb-0 text-500 text-capitalize">
                    {project_title}
                  </p>
                )}
              </h6>
              <p className="fs--2 mb-0 text-500 text-capitalize">
                {customer_name}
              </p>{" "}
            </div>
          </Flex>
        );
      },
    },
    {
      accessorKey: "progress",
      header: Translate("Progress"),
      meta: {
        headerProps: {
          className: "text-center text-800 fw-semi-bold",
        },
        cellProps: {
          className: "text-center",
        },
      },
      cell: ({ row: { original } }) => {
        const { project_progress_formatted } = original;
        return (
          <ProgressBar
            now={project_progress_formatted}
            style={{ height: 6 }}
            className="rounded-pill align-middle"
            variant="progress-gradient"
          />
        );
      },
    },
    {
      accessorKey: "duration",
      header: Translate("Worked"),
      meta: {
        cellProps: {
          className: "text-center fw-semibold fs-10",
        },
        headerProps: {
          className: "text-center fw-semi-bold text-800",
        },
      },
      cell: ({ row: { original } }) => {
        const { worked_hours } = original;
        return <p className="fs-11 mb-0 text-600">{worked_hours}</p>;
      },
    },
    {
      accessorKey: "date",
      header: Translate("Due Date"),
      meta: {
        cellProps: {
          className: "text-center fw-semibold fs-10",
        },
        headerProps: {
          className: "text-center fw-semi-bold text-800",
        },
      },
      cell: ({ row: { original } }) => {
        const { end_date } = original;
        return (
          <div className="flex-1 ms-3">
            <p className="fs-11 mb-0 text-600">{end_date.replace(/-/g, "/")}</p>{" "}
          </div>
        );
      },
    },
    {
      accessorKey: "members",
      header: Translate("Members"),
      enableSorting: false,
      meta: {
        headerProps: {
          className: "text-end text-800 fw-semi-bold",
        },
      },
      cell: ({ row: { original } }) => {
        const { project_members } = original;
        const maxVisibleAvatars = 3;
        return (
          <AvatarGroup className="justify-content-end">
            {project_members.slice(0, maxVisibleAvatars).map((member) => {
              return (
                <Avatar
                  src={member.image}
                  key={member.id}
                  name={member.full_name}
                  isExact
                  className="border border-3 rounded-circle border-200"
                />
              );
            })}
            {project_members.length > maxVisibleAvatars && (
              <Avatar
                name={`+ ${project_members.length - maxVisibleAvatars}`}
                className="border border-3 rounded-circle border-200 bg-light text-dark"
                isExact
              />
            )}
          </AvatarGroup>
        );
      },
    },
  ];

  const table = useAdvanceTable({
    data,
    columns,
    sortable: true,
    pagination: true,
    perPage: 6,
  });

  return (
    <>
      <Card className="h-100 card-main">
        <FalconCardHeader
          title="Running Projects"
          titleTag="h6"
          titleClass="fw-bold fs-0 mt-1"
        />
        <Card.Body className="p-0">
          {isLoading ? (
            <div className="px-3 pb-3">
              <ListLoading
                style={{ maxHeight: "34.5vh", overflow: "hidden" }}
              />
            </div>
          ) : (
            <>
              {data?.length > 0 ? (
                <RunningProjectAdvanceTable
                  table={table}
                  headerClassName="bg-body-tertiary text-nowrap align-middle"
                  rowClassName="align-middle white-space-nowrap"
                  tableProps={{
                    borderless: true,
                    className: "fs-11 mb-0 overflow-hidden",
                  }}
                />
              ) : (
                <div className="mt-3">
                  <NothingToShow />
                </div>
              )}
            </>
          )}
        </Card.Body>
        <Card.Footer className="p-0">
          <FalconCardFooterLink
            title="Show all projects"
            size="sm"
            to="/project"
          />
        </Card.Footer>
      </Card>
      <ProjectOffcanvas
        show={projectInfo?.show}
        id={projectInfo?.id}
        onHide={() => setProjectInfo({ show: false, id: "" })}
      />
    </>
  );
};

RunningProjects.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string.isRequired,
      progress: PropTypes.number.isRequired,
      duration: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      avatar: PropTypes.shape({
        name: PropTypes.string.isRequired,
        size: PropTypes.string,
      }),
      projectName: PropTypes.string.isRequired,
      members: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          img: PropTypes.string,
          size: PropTypes.string,
        })
      ),
      date: PropTypes.string.isRequired,
      isLast: PropTypes.bool,
    })
  ),
};

export default RunningProjects;
