import useAxisproTranslate from "hooks/useAxisproTranslate";
import React from "react";
import NodataImage from "assets/img/icons/spot-illustrations/NoData.png";

function NothingToShow() {
  const Translate = useAxisproTranslate();
  return (
    <div className="d-flex flex-column align-items-center justify-content-center text-center h-100">
      <img
        src={NodataImage}
        alt="No data"
        className="mb-3"
        style={{ maxWidth: "100%", height: "auto" }}
      />
      <p className="text-muted">{Translate("Nothing to show!")}</p>
    </div>
  );
}

export default NothingToShow;
