import React from "react";
import PropTypes from "prop-types";
import { Card } from "react-bootstrap";
import TicketSingleItem from "./TicketSingleItem";
import { statusData } from "./TicketStatusData";
import ListLoading from "module/common/ListLoading";
import NothingToShow from "../NothingToShow";

const TicketStatus = ({ data, isLoading }) => {
  const weeklyTicketCount = Object.keys(data.weekly_ticket_count || {}) || [];
  const onHoldTicketCount = weeklyTicketCount.map((item) => {
    return data.weekly_ticket_count[item]?.on_hold_ticket_count || 0;
  });
  const unassigned_ticket_count = weeklyTicketCount.map((item) => {
    return data.weekly_ticket_count[item]?.unassigned_ticket_count || 0;
  });
  const due_date_today_ticket_count = weeklyTicketCount.map((item) => {
    return data.weekly_ticket_count[item]?.due_date_today_ticket_count || 0;
  });
  const open_ticket_count = weeklyTicketCount.map((item) => {
    return data.weekly_ticket_count[item]?.open_ticket_count || 0;
  });

  return (
    <>
      {isLoading ? (
        <Card className="h-100 card-main p-3">
          <ListLoading style={{ maxHeight: "42vh", overflow: "hidden" }} />
        </Card>
      ) : (
        <Card className="h-100 card-main ">
          {Object.keys(data).length > 0 ? (
            <Card.Body>
              <TicketSingleItem
                count={data.on_hold_ticket_count}
                percentage={data.on_hold_ticket_percentage}
                title="On Hold Tickets"
                singleData={statusData[0]}
                graphData={onHoldTicketCount}
                isLoading={isLoading}
              />
              <TicketSingleItem
                count={data.opend_ticket_count}
                percentage={data.open_ticket_percentage}
                title="Open Tickets"
                singleData={statusData[1]}
                graphData={open_ticket_count}
                isLoading={isLoading}
              />
              <TicketSingleItem
                count={data.due_date_today_ticket_count}
                percentage={data.due_ticket_percentage}
                title="Due Tickets Today"
                singleData={statusData[2]}
                graphData={due_date_today_ticket_count}
                isLoading={isLoading}
              />
              <TicketSingleItem
                count={data.unassigned_ticket_count}
                percentage={data.unassigned_ticket_percentage}
                title="Unassigned Tickets"
                singleData={statusData[3]}
                graphData={unassigned_ticket_count}
                isLoading={isLoading}
              />
            </Card.Body>
          ) : (
            <div className="mt-3 h-100">
              <NothingToShow />
            </div>
          )}
        </Card>
      )}
    </>
  );
};

TicketStatus.propTypes = {
  data: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default TicketStatus;
