import React from "react";
import PropTypes from "prop-types";
import { Badge, Card } from "react-bootstrap";
import Flex from "components/common/Flex";
import RunningTaskChart from "./RunningTaskChart";
import DashboardTileLoader from "module/common/Loading/DashboardTileLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NothingToShow from "../NothingToShow";
import useAxisproTranslate from "hooks/useAxisproTranslate";

const RunningTaskTile = ({ data, isLoading }) => {
  const Translate = useAxisproTranslate();
  return (
    <>
      {isLoading ? (
        <Card className="h-100 p-3 py-4 card-main">
          <DashboardTileLoader column={1} />
        </Card>
      ) : (
        <Card className="h-100 py-3 card-main">
          <Card.Body
            as={Flex}
            justifyContent="around"
            alignItems="center"
            className="pt-0"
          >
            <div>
              <div className="mb-3">
                <h6
                  className="mb-3 flex-1 fw-bold fs-0 p-0"
                  style={{ width: "max-content" }}
                >
                  {Translate("Running Tasks")}
                </h6>
              </div>
              {Object.keys(data).length > 0 ? (
                <div className="d-flex justify-content-between align-items-start">
                  <div>
                    <h2 className="fw-medium text-600 fs-4">
                      {data?.running_task_count}
                    </h2>
                  </div>
                  <Badge
                    pill
                    bg="transparent"
                    className={`text-primary fs-10 px-2`}
                  >
                    <FontAwesomeIcon
                      icon={
                        parseFloat(data?.run_task_percentage) === 0
                          ? null
                          : parseFloat(data?.run_task_percentage) < 0
                          ? "caret-down"
                          : "caret-up"
                      }
                      className="me-1"
                    />
                    {data?.run_task_percentage}%
                  </Badge>
                </div>
              ) : (
                <div className="mt-3">
                  <NothingToShow />
                </div>
              )}
            </div>
            {Object.keys(data).length > 0 && (
              <div className="ps-0" style={{ height: "5.6rem", width: "70" }}>
                <RunningTaskChart
                  runningTaskCount={data?.running_task_count}
                  totalTaskCount={data?.total_task_count}
                />
              </div>
            )}
          </Card.Body>
        </Card>
      )}
    </>
  );
};

RunningTaskTile.propTypes = {
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default RunningTaskTile;
