import React from "react";
import PropTypes from "prop-types";
import { Card } from "react-bootstrap";
import Flex from "components/common/Flex";
import * as echarts from "echarts/core";
import { LineChart } from "echarts/charts";

import {
  GridComponent,
  TooltipComponent,
  TitleComponent,
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
import TotalTaskChart from "./TotalTaskChart";
import DashboardTileLoader from "module/common/Loading/DashboardTileLoader";
import NothingToShow from "../NothingToShow";
import useAxisproTranslate from "hooks/useAxisproTranslate";

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  CanvasRenderer,
]);

const getOptions = (data) => ({
  xAxis: {
    data: [30, 40, 20, 45, 32, 20, 15, 18, 10, 15],
  },
  series: [
    {
      type: "line",
      data,

      lineStyle: {
        width: 3,
      },

      areaStyle: {
        color: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: "#dce8f8",
            },
            {
              offset: 1,
              color: "#f8fbfe",
            },
          ],
        },
      },
    },
  ],
  grid: {
    bottom: "2%",
    top: "2%",
    right: "0px",
    left: "10px",
  },
});

const TotalTaskTile = ({ data, item, isLoading }) => {
  const Translate = useAxisproTranslate();
  return (
    <>
      {isLoading ? (
        <Card className="h-100 p-3 py-4 card-main">
          <DashboardTileLoader column={1} />
        </Card>
      ) : (
        <Card className="h-100 py-3 card-main">
          <Card.Body
            as={Flex}
            justifyContent="around"
            alignItems="center"
            className="pt-0"
          >
            <div>
              <div className="mb-3">
                <h6
                  className="mb-3 flex-1 fw-bold fs-0 p-0"
                  style={{ width: "max-content" }}
                >
                  {Translate("Total Tasks")}
                </h6>
              </div>
              {Object.keys(item).length > 0 ? (
                <div className="d-flex justify-content-between align-items-start">
                  <div>
                    <h2 className="fw-medium text-600 fs-4">
                      {item.total_task_count}
                    </h2>
                  </div>
                </div>
              ) : (
                <div className="mt-3">
                  <NothingToShow />
                </div>
              )}
            </div>
            {Object.keys(item).length > 0 && (
              <div className="ps-0">
                <TotalTaskChart
                  echarts={echarts}
                  options={getOptions(data)}
                  style={{ width: "8rem", height: 80 }}
                />
              </div>
            )}
          </Card.Body>
        </Card>
      )}
    </>
  );
};

TotalTaskTile.propTypes = {
  data: PropTypes.array.isRequired,
  item: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default TotalTaskTile;
