import axios from "axios";
import BackButton from "components/common/BackButton";
import { AuthWizardContext } from "context/Context";
import { resetCustomerPasswordFormKeys } from "helpers/formKeys";
import useAxisproTranslate from "hooks/useAxisproTranslate";
import { CustomBackButton } from "module/common/Buttons/AppButtons";
import { showToast } from "module/common/Toast/toast";
import {
  _validateConfirmPassword,
  _validatePassword,
} from "module/common/Validators/InputValidators";
import React, { useContext, useEffect, useState } from "react";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { MdLockReset } from "react-icons/md";

const ResetCustomerPasswordForm = ({ show, handleClose, handleLogout }) => {
  const { user } = useContext(AuthWizardContext);
  const Translate = useAxisproTranslate();
  const [formData, setFormData] = useState(resetCustomerPasswordFormKeys);
  const [valid, setValid] = useState({
    new_password: true,
    c_password: true,
  });
  const [formError, setFormError] = useState({});
  const [onSave, setOnSave] = useState(false);
  const [showPassword, setShowPassword] = useState({
    old_password: false,
    new_password: false,
    c_password: false,
  });

  const handleFieldChange = (e, action) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const updateValid = (formData) =>
    setValid((previousState) => ({
      ...previousState,
      ...formData,
    }));

  useEffect(() => {
    updateValid({
      new_password: _validatePassword(formData.new_password),
    });
    updateValid({
      c_password: _validateConfirmPassword(
        formData.new_password,
        formData.c_password
      ),
    });
  }, [formData.new_password, formData.c_password]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (onSave || !valid?.new_password || !valid?.c_password) return;
    setFormError({});
    setOnSave(true);
    axios({
      method: "post",
      url: `${
        user?.customer_id ? "/change-customer-password" : "/change-password"
      }`,
      headers: {
        "Content-Type": "application/json",
      },
      data: formData,
    })
      .then((response) => {
        if (response.data.success === true) {
          setFormData(resetCustomerPasswordFormKeys);
          showToast(response.data.message, "success");
          handleClose();
          handleLogout();
        } else {
          showToast(
            Translate(
              `Something went wrong, please refresh the page and try again.`
            ),
            "error"
          );
        }
      })
      .catch((error) => {
        const errorData = error?.response?.data;
        showToast(
          errorData?.message ??
            error?.message ??
            "Something went wrong, please refresh the page and try again.",
          "error"
        );
        setFormError({ ...(errorData?.data?.errors ?? null) });
      })
      .finally(() => {
        setOnSave(false);
      });
  };

  const togglePasswordVisibility = (field) => {
    setShowPassword((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  return (
    <Modal
      size="md"
      show={show}
      className="modal-with-overlay"
      animation
      centered
      onHide={handleClose}
    >
      <Modal.Header className="fs-1 text-dark d-flex justify-content-between">
        <span>
          <MdLockReset size={26} color="#2e2e2e" className="me-1" />
          {Translate("Reset Password")}
        </span>
        <CustomBackButton
          variant="falcon-default"
          title="Close"
          onClick={() => handleClose()}
        />
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formOldPassword">
            <Form.Label>{Translate("Old Password")}</Form.Label>
            <InputGroup>
              <Form.Control
                type={showPassword.old_password ? "text" : "password"}
                placeholder={Translate("Enter old password")}
                name="old_password"
                value={formData?.old_password}
                onChange={handleFieldChange}
                isInvalid={!!formError.old_password}
                autocomplete="off"
              />
              <InputGroup.Text
                className="cursor-pointer px-2"
                onClick={() => togglePasswordVisibility("old_password")}
              >
                {showPassword.old_password ? <FaEyeSlash /> : <FaEye />}
              </InputGroup.Text>
              <Form.Control.Feedback type="is-invalid">
                {formError.old_password}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>

          <Form.Group controlId="formNewPassword" className="mt-3">
            <Form.Label>{Translate("New Password")}</Form.Label>
            <InputGroup>
              <Form.Control
                type={showPassword.new_password ? "text" : "password"}
                placeholder={Translate("Enter new password")}
                name="new_password"
                value={formData?.new_password}
                onChange={handleFieldChange}
                isInvalid={
                  (formData.new_password !== "" && !valid.new_password) ||
                  !!formError.new_password
                }
                autoComplete="off"
              />
              <InputGroup.Text
                className="cursor-pointer px-2"
                onClick={() => togglePasswordVisibility("new_password")}
              >
                {showPassword.new_password ? <FaEyeSlash /> : <FaEye />}
              </InputGroup.Text>
              <Form.Control.Feedback type="is-invalid">
                {(formData.new_password !== "" &&
                  !valid.new_password &&
                  Translate("Password must include at least 4 character")) ||
                  formError.new_password}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>

          <Form.Group controlId="formConfirmPassword" className="mt-3">
            <Form.Label>{Translate("Confirm Password")}</Form.Label>
            <InputGroup>
              <Form.Control
                type={showPassword.c_password ? "text" : "password"}
                placeholder={Translate("Confirm new password")}
                name="c_password"
                value={formData?.c_password}
                onChange={handleFieldChange}
                isInvalid={
                  (formData.c_password !== "" && !valid.c_password) ||
                  !!formError.c_password
                }
                autoComplete="off"
              />
              <InputGroup.Text
                className="cursor-pointer px-2"
                onClick={() => togglePasswordVisibility("c_password")}
              >
                {showPassword.c_password ? <FaEyeSlash /> : <FaEye />}
              </InputGroup.Text>
              <Form.Control.Feedback type="is-invalid">
                {(formData.c_password !== "" &&
                  !valid.c_password &&
                  Translate("Passwords do not match")) ||
                  formError.c_password}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>

          <div className="mt-3">
            <div className="d-flex justify-content-end align-items-center">
              <Button
                size="sm"
                type="submit"
                variant={"info"}
                className="me-1"
                disabled={onSave || !valid?.new_password || !valid?.c_password}
              >
                {onSave ? Translate("Updating") + "..." : Translate("Update")}
              </Button>
              {!onSave && (
                <BackButton
                  variant={"danger"}
                  size={"sm"}
                  style={{ height: "fit-content" }}
                  onClick={handleClose}
                >
                  {Translate("Cancel")}
                </BackButton>
              )}
            </div>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ResetCustomerPasswordForm;
