import React from "react";
import * as echarts from "echarts/core";
import ReactEChartsCore from "echarts-for-react/lib/core";
import { getColor } from "helpers/utils";
import { useState, useEffect } from "react";
import axios from "axios";
import ListLoading from "module/common/ListLoading";
import NothingToShow from "../NothingToShow";

const DealStorageFunnelChart = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});
  const fetchData = () => {
    setIsLoading(true);
    axios
      .get("crm/opportunity-stage-wise-count")
      .then((res) => {
        if (res.data.success === true) {
          setData(res.data.data);
        }
      })
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const getOptions = () => {
    const stages = Object.keys(data);
    // const counts = stages.map((stage) => {
    //   if (data[stage]?.count > 0) {
    //     return data[stage]?.count || 0;
    //   } else {
    //     return null;
    //   }
    // });

    const percentage = stages.map((stage) => `${data[stage].percentage}%`);
    const percentages = stages.map((stage) => `${data[stage].percentage}`);

    return {
      yAxis: [
        {
          data: [
            "Qualification",
            " Needs analysis",
            "Proposal/Price Quote",
            "Negotiation/Review",
            "Closed Won",
            "Closed Lost",
            "Closed Lost to Competition",
          ],
          axisLabel: {
            inside: true,
            color: getColor("700"),
            fontWeight: 500,
            fontSize: 11,
            fontFamily: "poppins",
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          z: 10,
        },
        {
          data: percentage,
          axisLabel: {
            inside: false,
            color: getColor("primary"),
            fontWeight: 500,
            fontSize: 11,
            fontFamily: "poppins",
            borderRadius: 5,
            backgroundColor: getColor("soft-primary"),
            padding: [6, 16, 6, 16],
            width: 115,
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          z: 10,
        },
      ],
      xAxis: {
        type: "value",
        min: 0,
        max: 15,
        width: 20,
        axisLine: {
          show: false,
        },
        splitLine: {
          show: false,
        },
        inverse: true,
        axisTick: {
          show: false,
        },
        axisLabel: {
          show: false,
        },
      },

      // series: [
      //   {
      //     type: "bar",
      //     showBackground: true,
      //     barWidth: 25,
      //     label: {
      //       show: true,
      //       formatter: "{c} ",
      //       position: "insideLeft",
      //     },
      //     backgroundStyle: {
      //       color: getColor("200"),
      //       borderRadius: 5,
      //     },
      //     itemStyle: {
      //       color: getColor("primary"),
      //       borderRadius: 5,
      //     },
      //     data: percentages,
      //   },
      // ],
      series: [
        {
          type: "bar",
          showBackground: true,
          barWidth: 25,
          label: {
            show: false,
            formatter: "{c}% ",
            position: "insideRight",
            color: "#fff",
          },
          backgroundStyle: {
            color: getColor("200"),
            borderRadius: 5,
          },
          itemStyle: {
            borderRadius: 5,
            color: (params) => {
              const percentage = params.value;
              return {
                type: "linear",
                x: 1,
                y: 0,
                x2: 0,
                y2: 0,
                colorStops: [
                  {
                    offset: 0,
                    color: getColor("primary"),
                  },
                  {
                    offset: percentage / 100,
                    color: getColor("primary"),
                  },
                  {
                    offset: percentage / 100,
                    color: getColor("200"),
                  },
                  {
                    offset: 1,
                    color: getColor("200"),
                  },
                ],
              };
            },
          },
          data: percentages,
        },
      ],
      grid: { right: "65px", left: "0", bottom: "0", top: "0" },
    };
  };

  return (
    <>
      {isLoading ? (
        <ListLoading style={{ maxHeight: "29vh", overflow: "hidden" }} />
      ) : (
        <>
          {Object.keys(data).length > 0 ? (
            <ReactEChartsCore
              echarts={echarts}
              option={getOptions()}
              style={{ height: "15rem", marginTop: "20px", cursor: "default" }}
            />
          ) : (
            <div className="mt-3 h-100">
              <NothingToShow />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default DealStorageFunnelChart;
