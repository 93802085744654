import React, { useContext, useEffect, useState } from "react";
import {
  Outlet,
  createSearchParams,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import {
  Alert,
  Badge,
  Button,
  Card,
  Col,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import SimpleBar from "simplebar-react";
import { FaCircle, FaTrash } from "react-icons/fa";
import { RiEyeFill } from "react-icons/ri";
import { MdModeEdit } from "react-icons/md";
import Logo from "assets/img/logos/Accodax-3.png";
import "./Grid.scss";
import { GetAcodaxPermission } from "module/common/Permissions/AcodaxPermission";
import useAxisproTranslate from "hooks/useAxisproTranslate";
import AppPagination from "components/common/app-pagination/AppPagination";
import TableOverlayTrigger from "components/table-overlay-trigger/TableOverlayTrigger";
import getProjectFlagWithProps from "module/common/helpers/getProjectFlagWithProps";
import LoadingOverlay from "components/common/LoadingOverlay/LoadingOverlay";
import ProjectOffcanvas from "module/Project/ProjectOffcanvas/ProjectOffcanvas";
import { stringLimiter } from "helpers/utils";
import getProjectStatusWithProps from "module/common/helpers/getProjectStatusWithProps";
import CommonStatusUpdateDropDown from "components/common/DropDown/CommonStatusUpdateDropDown";
import { AuthWizardContext } from "context/Context";

function Grid({
  getData,
  data,
  allQueryParams,
  handleDelete,
  renderFrom,
  openFrom,
  filterVisibility,
}) {
  const { user } = useContext(AuthWizardContext);
  const { itemInfoId, client_id, empId } = useParams();
  const Translate = useAxisproTranslate();
  const fromProject = renderFrom === "project";
  const fromAccount = renderFrom === "account";
  const navigate = useNavigate();
  const location = useLocation();
  const isFromClient = openFrom === "account";
  const isFromEmployee = openFrom === "employee";
  const [statusLoading, setStatusLoading] = useState(false);
  const [projectInfo, setProjectInfo] = useState({
    show: false,
    id: "",
  });

  const permissions = {
    showProject: GetAcodaxPermission("PROJECT", "show"),
    updateProject: GetAcodaxPermission("PROJECT", "update"),
    deleteProject: GetAcodaxPermission("PROJECT", "delete"),
    showCustomer: GetAcodaxPermission("CUSTOMER", "show"),
    updateCustomer: GetAcodaxPermission("CUSTOMER", "update"),
    deleteCustomer: GetAcodaxPermission("CUSTOMER", "delete"),
    storeProject: GetAcodaxPermission("PROJECT", "store"),
    createCustomer: GetAcodaxPermission("CUSTOMER", "create"),
    listProject: GetAcodaxPermission("PROJECT", "list"),
  };

  const actionPermission =
    permissions.showProject ||
    permissions.showCustomer ||
    permissions.updateProject ||
    permissions.updateCustomer ||
    permissions.deleteProject ||
    permissions.deleteCustomer;

  const handleShowProjectInfo = (id) => {
    setProjectInfo({
      show: true,
      id: id,
    });
  };

  const handleNavigation = (id, actionFor) => {
    let routeTo;
    if (actionFor === "edit") {
      if (fromProject && openFrom) {
        routeTo = `project/edit/${id}?${createSearchParams({
          ...allQueryParams,
        })}`;
      } else {
        routeTo = fromProject
          ? `edit/${id}?${createSearchParams({
              ...allQueryParams,
            })}`
          : `/account/edit/${id}?${createSearchParams({
              ...allQueryParams,
              view: "grid",
            })}`;
      }
    } else if (actionFor === "overview") {
      if (fromProject && openFrom) {
        handleShowProjectInfo(id);
      } else if (fromProject) {
        handleShowProjectInfo(id);
      } else {
        routeTo = `/account/overview/${id}?tab=overview`;
      }
    }

    if (routeTo) {
      localStorage.setItem(
        fromAccount ? "actualRouteFromProject" : "actualRoute",
        `${location.pathname}${location.search}`
      );
      navigate(routeTo);
    }
  };

  const navigateWithPermission = (id) => {
    const permission = fromProject
      ? permissions.showProject
      : permissions.showCustomer;

    if (permission) {
      if (fromProject && openFrom) {
        handleShowProjectInfo(id);
      } else {
        if (fromProject) {
          handleShowProjectInfo(id);
        } else {
          navigate(`/account/overview/${id}?tab=overview`);
          localStorage.setItem(
            "actualRouteFromProject",
            `${location.pathname}${location.search}`
          );
        }
      }
    }
  };

  useEffect(() => {
    if (itemInfoId) {
      setProjectInfo({
        show: false,
        id: "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemInfoId]);

  return (
    <>
      <Outlet context={[getData]} />
      {statusLoading && <LoadingOverlay />}
      {!itemInfoId ? (
        data?.data?.length > 0 ? (
          <>
            <SimpleBar
              className={
                !filterVisibility
                  ? openFrom
                    ? "simplebar-style-with-pagination-for-tab"
                    : "simplebar-style-with-pagination"
                  : openFrom
                  ? "simplebar-style-with-pagination-and-filter-on-for-tab"
                  : "simplebar-style-with-pagination-and-filter-on"
              }
            >
              <div className="d-flex gap-3 flex-wrap p-3">
                {data?.data?.map((el, index) => (
                  <Card
                    style={{
                      width: "16rem",
                      backgroundColor: "whitesmoke",
                      color: "#000",
                    }}
                    className="common-grid-card-main-body"
                    key={index}
                  >
                    <Card.Header className="account-card-header p-0 ps-1 pe-4 mb-1 mt-2">
                      <Card.Img
                        variant="top"
                        className="account-card-header-img"
                        src={el?.logo ? el?.logo : Logo}
                      />
                    </Card.Header>
                    {fromProject && (
                      <div
                        className={`grid-flag`}
                        style={{
                          backgroundColor: getProjectFlagWithProps(el?.flag),
                        }}
                      >
                        <div className={`grid-flag-text`}>{el?.flag}</div>
                      </div>
                    )}
                    <Card.Body className="d-flex align-items-center flex-column p-1 pt-2">
                      <div
                        className="common-grid-card-body w-100 d-flex align-items-center flex-column"
                        onClick={() => navigateWithPermission(el?.id)}
                      >
                        <div className="p-2 mb-2 w-100 text-center">
                          <h1 className="fw-bolder mb-0 text-uppercase">
                            {fromProject
                              ? stringLimiter(el?.title, 35, "-")
                              : stringLimiter(el?.name, 35, "-")}
                          </h1>
                        </div>
                        <Row className="justify-content-center  w-100 mb-2 ">
                          <Col xs={5} className="p-0">
                            <h1 className="m-0">
                              {fromProject
                                ? Translate("Project Code:")
                                : Translate("Account Id:")}
                            </h1>
                          </Col>
                          <Col xs={6} className="p-0">
                            <h1 className="m-0 fw-bold">
                              {fromProject ? el?.project_code : el?.customer_id}
                            </h1>
                          </Col>
                        </Row>
                        <Row className="justify-content-center w-100 mb-2">
                          <Col xs={5} className="p-0">
                            <h1 className="m-0">
                              {fromProject
                                ? Translate("Start Date:")
                                : Translate("Email:")}
                            </h1>
                          </Col>
                          <Col xs={6} className="p-0">
                            <h1 className="m-0 fw-bold">
                              {fromProject
                                ? el?.start_date_formated
                                : el?.email
                                ? el?.email
                                : "N/A"}
                            </h1>
                          </Col>
                        </Row>
                        <Row className="justify-content-center  w-100 mb-2">
                          <Col xs={5} className="p-0">
                            <h1 className="m-0">
                              {fromProject
                                ? Translate("End Date:")
                                : Translate("Phone:")}
                            </h1>
                          </Col>
                          <Col xs={6} className="p-0">
                            <h1 className="m-0 fw-bold">
                              {fromProject ? el?.end_date_formated : el?.phone}
                            </h1>
                          </Col>
                        </Row>
                        {!fromProject && (
                          <Row className="justify-content-center  w-100 mb-2">
                            <Col xs={5} className="p-0">
                              <h1 className="m-0">{Translate("Type:")}</h1>
                            </Col>
                            <Col xs={6} className="p-0">
                              <h1 className="m-0 fw-bold">
                                {el?.customer_type}
                              </h1>
                            </Col>
                          </Row>
                        )}
                        {fromProject &&
                          !user?.customer_id &&
                          permissions.listProject && (
                            <Row className="justify-content-center  w-100 mb-2">
                              <Col xs={5} className="p-0">
                                <h1 className="m-0">{Translate("Price:")}</h1>
                              </Col>
                              <Col xs={6} className="p-0">
                                <h1 className="m-0 fw-bold">
                                  {el?.price_formatted}
                                </h1>
                              </Col>
                            </Row>
                          )}
                        {el?.customer?.name && (
                          <Row className="justify-content-center  w-100 mb-2">
                            <Col xs={5} className="p-0">
                              <h1 className="m-0">
                                {el?.customer?.name && Translate("Account:")}
                              </h1>
                            </Col>
                            <Col xs={6} className="p-0">
                              <h1 className="m-0 fw-bold">
                                <TableOverlayTrigger
                                  content={
                                    el?.customer?.name
                                      ? el?.customer?.name
                                      : "N/A"
                                  }
                                  length={12}
                                />
                              </h1>
                            </Col>
                          </Row>
                        )}
                        {fromAccount && (
                          <Row className="justify-content-center  w-100 mb-2">
                            <Col xs={5} className="p-0">
                              <h1 className="m-0">{Translate("Currency:")}</h1>
                            </Col>
                            <Col xs={6} className="p-0">
                              <h1 className="m-0 fw-bold">
                                {el?.currency_code ? el?.currency_code : "N/A"}
                              </h1>
                            </Col>
                          </Row>
                        )}
                      </div>
                      <Row className="justify-content-center  w-100 mb-2">
                        <Col xs={5} className="p-0">
                          <h1 className="m-0 text-dark">
                            {Translate("Status:")}
                          </h1>
                        </Col>
                        <Col xs={6} className="p-0">
                          {fromProject ? (
                            permissions?.updateProject ? (
                              <CommonStatusUpdateDropDown
                                getData={getData}
                                activeStatus={el?.status}
                                fetchUrl="crm/project-status"
                                method="patch"
                                postUrl={`crm/project/${el?.id}/status-update`}
                                setLoading={setStatusLoading}
                                width="126px"
                              />
                            ) : (
                              <span className="text-capitalize">
                                <FaCircle
                                  color={getProjectStatusWithProps(el?.status)}
                                  size={10}
                                />
                                <span className="ms-1">
                                  {el?.status.replace("_", " ")}
                                </span>
                              </span>
                            )
                          ) : (
                            <Badge
                              className="text-uppercase fw-bolder"
                              bg={el?.inactive !== 0 ? "warning" : "success"}
                            >
                              {el?.inactive !== 0
                                ? Translate("Inactive")
                                : Translate("Active")}
                            </Badge>
                          )}
                        </Col>
                      </Row>
                    </Card.Body>
                    {actionPermission && (
                      <Card.Footer
                        style={{
                          backgroundColor: "#d5d5d5b8",
                        }}
                        className="p-1 d-flex align-items-center justify-content-evenly mt-2 mb-2 rounded m-2"
                      >
                        {(fromProject
                          ? permissions.showProject
                          : permissions.showCustomer) && (
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip style={{ fontSize: 11 }}>
                                {Translate("Click here to view!")}
                              </Tooltip>
                            }
                          >
                            <Button
                              variant="transparent"
                              className="text-dark  account-card-action-btn account-card-view"
                              onClick={() =>
                                handleNavigation(el.id, "overview")
                              }
                            >
                              <RiEyeFill color="#4a79b7" size={18} />
                            </Button>
                          </OverlayTrigger>
                        )}
                        {(fromProject
                          ? permissions.updateProject
                          : permissions.updateCustomer) && (
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip style={{ fontSize: 11 }}>
                                {Translate("Click here to Edit!")}
                              </Tooltip>
                            }
                          >
                            <Button
                              variant="transparent"
                              className="account-card-action-btn account-card-edit"
                              onClick={() => handleNavigation(el.id, "edit")}
                            >
                              <MdModeEdit color="#e96624" size={20} />
                            </Button>
                          </OverlayTrigger>
                        )}
                        {(fromProject
                          ? permissions.deleteProject
                          : permissions.deleteCustomer) && (
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip style={{ fontSize: 11 }}>
                                {Translate("Click here to delete!")}
                              </Tooltip>
                            }
                          >
                            <Button
                              variant="transparent"
                              className="account-card-action-btn account-card-delete"
                              onClick={() => handleDelete(el?.id)}
                            >
                              <FaTrash color="#d52525" size={15} />
                            </Button>
                          </OverlayTrigger>
                        )}
                      </Card.Footer>
                    )}
                  </Card>
                ))}
              </div>
            </SimpleBar>
            <div className="border-top p-1 pt-3 footer">
              {data && data.links && (
                <AppPagination
                  data={data}
                  url={
                    isFromClient
                      ? `/account/overview/${client_id}`
                      : isFromEmployee
                      ? `/employee/overview/${empId}`
                      : fromProject
                      ? "/project"
                      : "/account/grid"
                  }
                  search={allQueryParams}
                />
              )}
            </div>
          </>
        ) : (
          <Alert
            variant="danger"
            className="text-center p-2 fs--1"
            style={{ color: "rgb(94,111,131)", borderRadius: "0px" }}
          >
            {Translate("No Data Available Let's Add It 🙂")}
          </Alert>
        )
      ) : (
        ""
      )}
      {!itemInfoId && (
        <ProjectOffcanvas
          show={projectInfo?.show}
          id={projectInfo?.id}
          onHide={() => setProjectInfo({ show: false, id: "" })}
        />
      )}
    </>
  );
}

export default Grid;
